// import home_logo from '/images/home-logo.png';
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../redux/auth";
import ApiService from "../../services/ApiService";
import { signOut } from "firebase/auth";
import { firebase_auth } from "../../FirebaseSetup";

function Header() {
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated, userId } = useSelector((state) => state.auth);

  const Logout = async () => {
    /////////////////// Api call ///////////////////////
    await new ApiService().POST("/logout", { userid: userId });
    signOut(firebase_auth);
    dispatch(authActions.Logout());
    navigate("/");
  };

  return (
    <>
      <header id="header" className="defualt">
        {/* <div className="topbar">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="topbar-left text-start">
                  <ul>
                    <li>
                      <a href="mailto:support@website.com">
                        <i className="fa fa-envelope-o"> </i>{" "}
                        support@website.com{" "}
                      </a>
                    </li>
                    <li>
                      <a href="tel:(007)1234567890">
                        <i className="fa fa-phone" /> (007) 123 456 7890{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="topbar-right text-end">
                  <ul className="list-inline social-icons color-hover">
                    <li className="social-facebook">
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="social-twitter">
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="social-instagram">
                      <a href="#">
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li className="social-dribbble">
                      <a href="#">
                        <i className="fa fa-dribbble" />
                      </a>
                    </li>
                  </ul>
                  {!isAuthenticated && (
                    <>
                      <ul className="list-inline text-uppercase top-menu">
                        <li>
                          <NavLink to={"/register"}>register</NavLink>
                        </li>
                        <li>
                          <NavLink to={"/login"}>login</NavLink>
                        </li>
                      </ul>
                    </>
                  )}

                  {isAuthenticated && (
                    <ul className="list-inline text-uppercase top-menu">
                      <li style={{ cursor: "pointer" }} onClick={Logout}>
                        Logout
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/*================================= mega menu ================*/}
        <div className="menu">
          {/* menu start */}
          <nav id="menu" className="mega-menu">
            {/* menu list items container */}
            <section className="menu-list-items">
              <div className="container">
                <div className="row position-relative">
                  <div className="col-md-12 position-relative">
                    {/* menu logo */}
                    <ul className="menu-logo">
                      <li>
                        {" "}
                        <Link to={"/"}>
                          <img src="/images/home-logo.png" alt="logo" />{" "}
                        </Link>
                        <div class="custom-mobile-menu active">
                          <span></span>
                        </div>
                      </li>
                    </ul>
                    {/* menu links */}
                    <ul className="menu-links">
                      {/* active class */}
                      <li className="active">
                        {!isAuthenticated &&
                          <NavLink to={"/"}> Home </NavLink>
                        }
                      </li>
                      <li>
                        {!isAuthenticated &&
                          <NavLink to="/blogs"> Blog </NavLink>
                        }
                      </li>
                      <li>
                        {!isAuthenticated &&
                          <NavLink to="/about-us"> About Us</NavLink>
                        }
                      </li>
                      <li>
                        {!isAuthenticated &&
                          <NavLink to={"/contact"}> Contact Us </NavLink>
                        }
                      </li>
                      {isAuthenticated && (
                        <li>
                          <NavLink to={"/find-match"}> Home </NavLink>
                        </li>
                      )}
                      {isAuthenticated && (
                          <li>
                            <NavLink to={"/connections"}> Connections </NavLink>
                          </li>
                        )}
                      {isAuthenticated && (
                        <li>
                          <NavLink to={"/profile"}> Profile </NavLink>
                        </li>
                      )}
                      {isAuthenticated && (
                        <li>
                          <NavLink to={"/chat"}> Chat </NavLink>
                        </li>
                      )}

                      {!isAuthenticated && (
                        <>
                          <li>
                            <NavLink to={"/register"}>register</NavLink>
                          </li>
                          <li>
                            <NavLink to={"/login"}>login</NavLink>
                          </li>
                        </>
                      )}

                      {isAuthenticated && (
                        <li>
                          <Link to="#" onClick={Logout}>Logout</Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </nav>
          {/* menu end */}
        </div>
      </header>
    </>
  );
}

export default Header;
