import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import PreLoader from "../PreLoader";
import moment from "moment";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { useQuery } from '@tanstack/react-query';

function Index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Items, setItems] = useState([]);

    const getNotificationList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-notifications/${userData.id}`);
        console.log("get-notifications ", responce.data);

        // return;

        if (responce.data.status === "1") {
            setItems(responce.data.data);
        }

        setLoader(false);
    }, []);


    const notificationDelete = async (id = "", userid = "", title = "") => {

        // "id":"", // id use for single delete or leve it blank ("")
        // "userid":""  // userid use for All delete or leve it blank ("")


        Swal.fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                /////////////////// Api call ///////////////////////
                var responce = await new ApiService().DELETE(`/delete-notifications`, {
                    id: id,
                    userid: userid
                });
                console.log("delete-notification ", responce.data);

                // return;

                if (responce.data.status === "1") {
                    getNotificationList();
                }
            }
        });
    }


    //// notification view update ////
    const {
        data: notificationData = {},
    } = useQuery({
        queryKey: ['notificationViewStatusUpdate'],
        queryFn: async () => {
            /////////////////// Api call ///////////////////////
            var response = await new ApiService().GET(`/change-notification-status/${userData.id}`);
            console.log("notificationViewStatusUpdate ", response.data);
            return response.data;
        },
    })

    useEffect(() => {
        getNotificationList();
    }, []);

    return (
        <>
            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}
            <div className="connections">
                <h1>My Notifications</h1>

                <div className="d-flex justify-content-end">
                    {Items.length > 0 && <Button className="btn btn-sm btn-danger mb-3" onClick={() => notificationDelete("", userData.id, "Do you want to delete all Notifications?")}>
                        <i className="fa-solid fa-trash"></i> Clear All
                    </Button>}
                </div>

                {Items.map((item, index) => (<>
                    <div className="profile" key={index}>
                        <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" onClick={() => navigate("/view-profile", {
                            state: {
                                profile_id: item.req_senderid,
                                page_show_permission: true
                            }
                        })} />
                        <div className="details">
                            <h2>{item.name}</h2>
                            <p>{item.message}</p>
                        </div>

                        <p>{moment(item.notfication_time).format("DD/MM/YYYY")}</p>

                        <Button className="btn btn-sm btn-danger m-3" onClick={() => notificationDelete(item.id, "", "Do you want to delete this record?")}>
                            <i className="fa-solid fa-trash"></i>
                        </Button>

                    </div>
                </>))}


                {(Items.length === 0) && (<>
                    <div className="profile">
                        <div className="details">
                            <h2>NO Notifications Found</h2>
                        </div>

                    </div>
                </>)}


            </div>
        </>
    );
}

export default memo(Index);
