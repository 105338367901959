import { NavLink } from "react-router-dom";
import PreLoader from "../../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserProfileSetupSchama } from "../../../Schemas";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiService from "../../../services/ApiService";
import { useSelector } from "react-redux";
import { Country } from "country-state-city";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/auth";
import Select from 'react-select';


const heightData = [
  { label: 'Under 5’0"', value: '5.0' },
  { label: '5’1"', value: '5.1' },
  { label: '5’2"', value: '5.2' },
  { label: '5’3"', value: '5.3' },
  { label: '5’4"', value: '5.4' },
  { label: '5’5"', value: '5.5' },
  { label: '5’6"', value: '5.6' },
  { label: '5’7"', value: '5.7' },
  { label: '5’8"', value: '5.8' },
  { label: '5’9"', value: '5.9' },
  { label: '6’0"', value: '6.0' },
  { label: '6’1"', value: '6.1' },
  { label: '6’2"', value: '6.2' },
  { label: '6’3"', value: '6.3' },
  { label: '6’4"', value: '6.4' },
  { label: '6’5"', value: '6.5' },
  { label: '6’6"', value: '6.6' },
  { label: '6’7"', value: '6.7' },
  { label: '6’8"', value: '6.8' },
  { label: '6’9"', value: '6.9' },
  { label: '7’0"', value: '7.0' },
  { label: '7’1"', value: '7.1' },
  { label: '7’2"', value: '7.2' },
  { label: '7’3"', value: '7.3' },
  { label: '7’4"', value: '7.4' },
  { label: '7’5"', value: '7.5' },
]

function Index() {
  const [SelectedCountry, setSelectedCountry] = useState(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [countries, setCountries] = useState([]);


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated, userData, identificationStatus } = useSelector(
    (state) => state.auth
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-undef
  const [PreviewImage, setPreviewImage] = useState("/images/demo-one.png");



  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    // getValues,
    // trigger,
    // control,
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({
    resolver: yupResolver(UserProfileSetupSchama),
    mode: "all",
  });

  const uploadPreviewImage = (e) => {
    // var file=e.currentTarget.files[0];
    // const reader = new FileReader();

    // reader.readAsDataURL(file);

    // setPreviewImage(reader.result);

    // setValue("profile_image", file);

    const file = e.target.files[0];
    // if (file) {
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setValue('profile_image', reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // }

    if (file) {
      // Display the preview of the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setValue("profile_pic", e.target.files[0]);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage("/images/demo-one.png");
      setValue("profile_pic", null);
    }
  };

  const submit = async (data) => {
    // console.log(data)

    setLoader(true);

    var profile_data = {
      bodyType: data.bodyType,
      height: data.height,
      education: data.education,
      marital: data.marital,
      occupation: data.occupation,
      gender: data.gender,
      zipcode: data.zipcode,
      city: data.city,
      country: data.country,
      age: data.age,
      name: data.name,
      userid: userData.id,
    };

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/profile-update", profile_data);

    if (responce.data.status == "1") {
      var formData = new FormData();
      formData.append("profile_pic", data.profile_pic);
      formData.append("userid", userData.id);

      /////////////////// Api call ///////////////////////
      var pic_upload_responce = await new ApiService().POST(
        "/new-upload-profile-pic",
        formData,
        "file"
      );

      // console.log(pic_upload_responce.data)

      if (pic_upload_responce.data.status == "1") {
        reset();

        /////////////////// Api call ///////////////////////
        var responce2 = await new ApiService().POST("/user-info", {
          userid: userData.id,
        });

        var resData = responce2.data;
        // console.log("responce2",resData);
        dispatch(authActions.Login(resData));

        dispatch(authActions.ChangeIdentificationStatus(true));

        navigate("/preferences", {
          state: {
            page_show_permission: true,
          },
        });
      } else {
        toast.error(responce.data.message);
      }
    } else {
      toast.error(responce.data.message);
    }

    setLoader(false);

    // console.log(responce.data)
  };



  const ChooseCountry = (data) => {
    setSelectedCountry(data);

    // console.log(data)

    setValue("country", data.value)

  }

  // Fetch the list of countries on component mount
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    ///////////// page_show_permission Check ///////////////
    if (location.state === null) {
      navigate(-1);
    } else {
      if (!location.state.page_show_permission) {
        navigate(-1);
      } else {
        if (identificationStatus) {
          navigate(-1);
        }
      }
    }
    ///////////// page_show_permission Check ///////////////

    const fetchCountries = () => {
      const countryList = Country.getAllCountries();
      const options = countryList.map(item => ({ value: item.isoCode, label: item.name }));

      setCountries(options);
    };

    fetchCountries();
  }, []);

  return (
    <>
      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}

      {/* ================================= inner-intro */}
      <section
        className="inner-intro bg bg-fixed bg-overlay-black-60"
        style={{ backgroundImage: "url(/images/bg/main-breadcrumb.jpg)" }}
      >
        <div className="container">
          <div className="row intro-title text-center">
            <div className="col-md-12">
              <div className="section-title">
                <h1 className="position-relative divider">
                  Identification
                  <span className="sub-title w-100">Identification</span>
                </h1>
              </div>
            </div>
            <div className="col-md-12 mt-7">
              <ul className="page-breadcrumb">
                <li>
                  <NavLink to={"/"}>
                    <i className="fa fa-home" /> Home
                  </NavLink>
                  <i className="fa fa-angle-double-right" />
                </li>
                <li>
                  <a href="#">Pages</a>
                  <i className="fa fa-angle-double-right" />
                </li>
                <li>
                  <span>User Profile Setup</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*================================= inner-intro*/}

      {/*================================= login*/}
      <section
        className="page-section-ptb profile-setup"
        style={{
          background: "url(/images/pattern/04.png) no-repeat 0 0",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div>
                <form onSubmit={handleSubmit(submit)}>
                  <div className="upload-profile">
                    <img src={PreviewImage} alt="profile" />
                    <label className="form_label">
                      <img
                        src={"/images/upload.svg"}
                        alt="add-icon"
                        width={30}
                      />
                      <input
                        type="file"
                        className="form-control"
                        id="uploadFile"
                        accept={".jpg,.gif,.png,.jpeg,.svg,.webp"}
                        onChange={uploadPreviewImage}
                      />
                    </label>

                    <p style={{ color: "red" }} className="form-field-error">
                      {errors.profile_pic?.message}
                    </p>
                  </div>
                  <h4>Upload your picture!</h4>

                  <label htmlFor="">Name</label>
                  <input
                    className="profile-input"
                    type="text"
                    placeholder="Name"
                    {...register("name")}
                  />
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.name?.message}
                  </p>

                  <label htmlFor="">Age</label>
                  <input
                    className="profile-input"
                    type="number"
                    placeholder="Age"
                    {...register("age")}
                  />
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.age?.message}
                  </p>

                  <label htmlFor="">Height</label>
                  <select className="profile-input" {...register("height")}>
                    <option value="">Select Height</option>
                    {heightData.map((item, index) => (<>
                      <option value={item.value}>{item.label}</option>
                    </>))}
                  </select>
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.height?.message}
                  </p>

                  <label htmlFor="">Country</label>
                  {/* <select className="profile-input" {...register("country")}>
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select> */}
                  <Select
                    options={countries}
                    onChange={(selectedOption) => ChooseCountry(selectedOption)}
                    value={SelectedCountry}
                    className="mt-2"
                  />
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.country?.message}
                  </p>

                  <label htmlFor="">City</label>
                  <input
                    className="profile-input"
                    type="text"
                    placeholder="City"
                    {...register("city")}
                  />
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.city?.message}
                  </p>

                  <label htmlFor="">Zip Code</label>
                  <input
                    className="profile-input"
                    type="text"
                    placeholder="Zip Code"
                    {...register("zipcode")}
                  />
                  <p style={{ color: "red" }} className="form-field-error">
                    {errors.zipcode?.message}
                  </p>

                  <div className="select-gender ">
                    <div className="sel-radio">
                      <span className="sel-heading mt-4">Gender</span>
                      <div className="radio-buttons">
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="male"
                            {...register("gender")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Male</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="female"
                            {...register("gender")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Female</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="other"
                            {...register("gender")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Other</span>
                        </label>
                      </div>
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.gender?.message}
                      </p>
                      {/* /////////////////// End gender //////////////// */}
                      <span className="sel-heading mt-4">Marital</span>
                      <div className="radio-buttons">
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="single"
                            {...register("marital")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Single</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="divorced"
                            {...register("marital")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Divorced</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="other"
                            {...register("marital")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Other</span>
                        </label>
                      </div>
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.marital?.message}
                      </p>
                      {/* /////////////////// End marital //////////////// */}
                      <span className="sel-heading mt-4">Education</span>
                      <div className="radio-buttons">

                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="no_degree"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">No Degree</span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="high_school_diploma_or_equivalent"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">
                            High School Diploma / Equivalent
                          </span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="some_college"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Some College</span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="associate_degree"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Associate Degree</span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="bachelor"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Bachelor</span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="master_degree"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Master Degree</span>
                        </label>


                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="doctorate_post_doctorate"
                            {...register("education")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Doctorate / Post-Doctorate</span>
                        </label>


                      </div>
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.education?.message}
                      </p>
                      {/* /////////////////// End education //////////////// */}


                      <span className="sel-heading mt-4">Occupation</span>
                      <div className="radio-buttons">
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="employed"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Employed</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="retired"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Retired</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="student"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Student</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="self-employed"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Self-Employed</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="unemployed"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Unemployed</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="other"
                            {...register("occupation")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Other</span>
                        </label>
                      </div>
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.occupation?.message}
                      </p>
                      {/* /////////////////// End occupation //////////////// */}
                      <span className="sel-heading mt-4">Body Type</span>
                      <div className="radio-buttons">
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="athletic"
                            {...register("bodyType")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Athletic</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="average"
                            {...register("bodyType")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Average</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="petite"
                            {...register("bodyType")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Petite</span>
                        </label>
                        <label className="radio-button">
                          <input
                            type="radio"
                            defaultValue="thick"
                            {...register("bodyType")}
                          />
                          <div className="radio-circle" />
                          <span className="radio-label">Thick</span>
                        </label>
                      </div>
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.bodyType?.message}
                      </p>
                      {/* /////////////////// End bodyType //////////////// */}
                    </div>
                  </div>

                  {/*=============  Range-select ============== */}
                  {/* <div className="form-group mb-3">
                                        <span className="sel-heading mt-4">Age Preference</span>
                                        <div className="prefarence">
                                            <input
                                                id="slider1"
                                                className="range-slider"
                                                data-slider-id="ex1Slider1"
                                                type="text"
                                                data-slider-min={0}
                                                data-slider-max={30}
                                                data-slider-step={1}
                                                data-slider-value={18}
                                            />
                                            <input
                                                id="slider2"
                                                className="range-slider"
                                                data-slider-id="ex1Slider2"
                                                type="text"
                                                data-slider-min={30}
                                                data-slider-max={100}
                                                data-slider-step={1}
                                                data-slider-value={30}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <span className="sel-heading mt-4">Select Radius</span>
                                        <div className="prefarence">
                                            <input
                                                id="slider3"
                                                className="range-slider"
                                                data-slider-id="ex1Slider1"
                                                type="text"
                                                data-slider-min={0}
                                                data-slider-max={35}
                                                data-slider-step={1}
                                                data-slider-value={30}
                                            />
                                            <input
                                                id="slider4"
                                                className="range-slider"
                                                data-slider-id="ex1Slider2"
                                                type="text"
                                                data-slider-min={36}
                                                data-slider-max={200}
                                                data-slider-step={1}
                                                data-slider-value={160}
                                            />
                                        </div>
                                    </div> */}
                  {/*============= End Range-select =============*/}

                  <div className="form-group text-center mt-3 mb-0">
                    <button
                      type="submit"
                      className="button btn-theme full-rounded btn nextBtn btn-lg mt-2 animated right-icn"
                    >
                      <span>
                        Save &amp; Continue
                        <i
                          className="glyph-icon flaticon-hearts"
                          aria-hidden="true"
                        />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*=================================
 login*/}
    </>
  );
}

export default Index;
