import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import Funds from "./Funds";
import EditMatchPreferences from "./EditMatchPreferences";
import ChangePassword from "./ChangePassword";
import AddPhotos from "./AddPhotos";
import ChatPackages from "./ChatPackages";
import { useEffect, useRef, useState } from "react";
import PreLoader from "../PreLoader";
import ApiService from "../../services/ApiService";
import { authActions } from "../../redux/auth";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Wishlist from "./Wishlist";
import OutgoingCallHistory from "./OutgoingCallHistory";
import BlockUser from "./BlockUser";
import RatingList from "./RatingList";
import IncomingCallsHistory from "./IncomingCallsHistory";
import WalletTransactions from "./WalletTransactions";
import ChatPackagesTransactions from "./ChatPackagesTransactions";
import Swal from "sweetalert2";
import { signOut } from "firebase/auth";
import { firebase_auth } from "../../FirebaseSetup";

const menu_list = {
  editProfile: "editProfile",
  editMatchPreferences: "editMatchPreferences",
  addWalletFunds: "addWalletFunds",
  outgoingCallHistory: "outgoingCallHistory",
  incomingCallHistory: "incomingCallHistory",
  walletTransactions: "walletTransactions",
  chatPackagesTransactions: "chatPackagesTransactions",
  chatPackagesPlans: "chatPackagesPlans",
  changePassword: "changePassword",
  blockUser: "blockUser",
  addPhotos: "addPhotos",
  wishlist: "wishlist",
}

function Index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useSelector((state) => state.auth);

  const [Menu, setMenu] = useState(menu_list.editProfile);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-undef
  const [PreviewImage, setPreviewImage] = useState("/images/demo-one.png");

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  const location = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();

  // Create refs for each button
  const FundsButtonRef = useRef(null);
  const PackagesButtonRef = useRef(null);

  const uploadPreviewImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Display the preview of the image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        // setValue_2("profile_pic", e.target.files[0]);
        imgSubmit(e.target.files[0]);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage("/images/demo-one.png");
      // setValue_2("profile_pic", null);
    }
  };

  const imgSubmit = async (data) => {
    // console.log("imgSubmit ",data)
    // return;
    if (data) {
      setLoader(true);

      var formData = new FormData();
      formData.append("profile_pic", data);
      formData.append("userid", userData.id);

      /////////////////// Api call ///////////////////////
      var pic_upload_responce = await new ApiService().POST(
        "/new-upload-profile-pic",
        formData,
        "file"
      );

      // console.log(pic_upload_responce.data)

      if (pic_upload_responce.data.status == "1") {
        toast.success("Profile picture upload successfully");
        await getUserDetails();
        // reset_2();
        setPreviewImage("/images/demo-one.png");
      }
    }
  };

  const getUserDetails = async () => {
    // setLoader(true);

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/user-info", {
      userid: userData.id,
    });

    var resData = responce.data.user_details[0];
    // console.log("getUserDetails ", resData);
    dispatch(authActions.UpdateUserData(resData));

    setLoader(false);
  };


  const deleteAccount = async () => {
    Swal.fire({
      title: "Are you sure you want to delete your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete account",
    }).then(async (result) => {
      if (result.isConfirmed) {
        /////////////////// Api call ///////////////////////
        await new ApiService().POST("/delete-user-account", { user_id: userData.id });
        signOut(firebase_auth);
        dispatch(authActions.Logout());
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (location.state != null) {
      if (location.state.tab_name) {
        if (location.state.tab_name !== "") {
          if (location.state.tab_name === "funds") {
            FundsButtonRef.current.click();
            setMenu(menu_list.addWalletFunds);
          } else if (location.state.tab_name === "packages") {
            PackagesButtonRef.current.click();
            setMenu(menu_list.chatPackagesPlans);
          }
        }
      }
    }
  }, []);

  return (
    <>
      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}

      {/* ======== User-Setting ========S */}
      <section className="user-profile">
        <div className="container">
          <div className="user-profile-header">
            <div className="upload-pro-container">
              <div className="pro">
                <img
                  src={userData.profile_pic_pro_resize_200_url}
                  alt="profile"
                />
              </div>
              <label className="pro-upload">
                <img src="/images/upload.svg" alt="upload" />
                <input
                  type="file"
                  class="form-control"
                  id="uploadFile"
                  accept={".jpg,.gif,.png,.jpeg,.svg,.webp"}
                  onChange={uploadPreviewImage}
                ></input>
              </label>
            </div>
            <span>{userData.name}</span>
            <p>{userData.email}</p>
          </div>
          <div className="d-flex align-items-start">
            <div
              className="nav flex-column nav-pills me-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link active"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={() => setMenu(menu_list.editProfile)}
              >
                Edit Profile
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-match-preference"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={() => setMenu(menu_list.editMatchPreferences)}
              >
                Match Preferences
              </button>
              <button
                ref={FundsButtonRef}
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-funds"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.addWalletFunds)}
              >
                Add Wallet Funds
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#wallet-transactions"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.walletTransactions)}
              >
                Wallet Funds History
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#outgoing-calls-history"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.outgoingCallHistory)}
              >
                Outgoing Audio/Video calls history
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#incoming-calls-history"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.incomingCallHistory)}
              >
                Incoming Audio/Video calls history
              </button>
              <button
                className="nav-link"
                type="button"
                onClick={() => navigate("/connections")}
              >
                Connections
              </button>


              <button
                ref={PackagesButtonRef}
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#packages"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.chatPackagesPlans)}
              >
                Purchase Chat Package Plans
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#chat-packages-transactions"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.chatPackagesTransactions)}
              >
                Chat Package Transactions
              </button>
              {userData.user_type === "0" && (
                <button
                  className="nav-link"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#tab-change-password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                  onClick={() => setMenu(menu_list.changePassword)}
                >
                  Change Password
                </button>
              )}
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-blocked"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.blockUser)}
              >
                Block List
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-ad-photos"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.addPhotos)}
              >
                Add Photos
              </button>
              <button
                className="nav-link"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#tab-wishlist"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => setMenu(menu_list.wishlist)}
              >
                Wishlist
              </button>
              {/* <button
                className="nav-link"
                id="tabrate-tab"
                data-bs-toggle="pill"
                data-bs-target="#tabrate"
                type="button"
                role="tab"
                aria-controls="tabrate"
                aria-selected="false"
              >
                Rate
              </button> */}

              {/* // terms and conditions // */}
              <button
                className="nav-link"
                type="button"
                onClick={() => navigate("/terms-condition")}
              >
                Terms & Conditions
              </button>

              {/* // privacy policy // */}
              <button
                className="nav-link"
                type="button"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </button>

              {/* // Delete account // */}
              <button
                className="nav-link"
                type="button"
                onClick={deleteAccount}
              >
                Delete Account
              </button>
            </div>



            <div className="tab-content" id="v-pills-tabContent">
              {/* //////////// Edit Profile /////////////////// */}
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabIndex={0}
              >
                {Menu === 'editProfile' && <EditProfile />}
              </div>

              {/* //////////// Edit Match Preference /////////////////// */}
              <div
                className="tab-pane fade"
                id="v-pills-match-preference"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
                tabIndex={0}
              >
                {Menu === 'editMatchPreferences' && <EditMatchPreferences />}
              </div>

              {/* //////////// Funds /////////////////// */}
              <div
                className="tab-pane fade"
                id="tab-funds"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'addWalletFunds' && <Funds />}
              </div>

              {/* //////////// Outgoing Call History /////////////////// */}
              <div
                className="tab-pane fade"
                id="outgoing-calls-history"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'outgoingCallHistory' && <OutgoingCallHistory />}
              </div>

              {/* //////////// Incoming Call History /////////////////// */}
              <div
                className="tab-pane fade"
                id="incoming-calls-history"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'incomingCallHistory' && <IncomingCallsHistory />}
              </div>

              {/* //////////// Wallet Transactions /////////////////// */}
              <div
                className="tab-pane fade"
                id="wallet-transactions"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'walletTransactions' && <WalletTransactions />}
              </div>

              {/* //////////// Chat Packages Transactions /////////////////// */}
              <div
                className="tab-pane fade"
                id="chat-packages-transactions"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'chatPackagesTransactions' && <ChatPackagesTransactions />}
              </div>

              {/* //////////// ChatPackages /////////////////// */}
              <div
                className="tab-pane fade"
                id="packages"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'chatPackagesPlans' && <ChatPackages />}
              </div>

              {/* ////////////// Change password /////////// */}
              <div
                className="tab-pane fade"
                id="tab-change-password"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'changePassword' && <ChangePassword />}
              </div>

              {/* ////////////// BlockUser /////////// */}
              <div
                className="tab-pane fade"
                id="tab-blocked"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'blockUser' && <BlockUser />}
              </div>

              {/* ////////////// Add Photos /////////// */}
              <div
                className="tab-pane fade"
                id="tab-ad-photos"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'addPhotos' && <AddPhotos />}
              </div>

              {/* ////////////// wishlist /////////// */}
              <div
                className="tab-pane fade"
                id="tab-wishlist"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
                tabIndex={0}
              >
                {Menu === 'wishlist' && <Wishlist />}
              </div>

              {/* ////////////// RatingList /////////// */}
              {/* <div
                className="tab-pane fade"
                id="tabrate"
                role="tabpanel"
                aria-labelledby="tabrate-tab"
                tabIndex={0}
              >
                <RatingList />
              </div> */}
            </div>

          </div>
        </div>
      </section>
      {/* ======== User-Setting ========E */}
    </>
  );
}

export default Index;
