import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import PreLoader from "../../PreLoader";


function OtpVerify() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Email, setEmail] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [Code, setCode] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);



    const submit = async () => {
        try {

            // alert(Email);
            // alert(Code);

            if (Code.length < 4) {
                toast.warning("Please enter OTP");
                return;
            }

            setLoader(true);

            var data = {
                email: Email,
                forgotkey: Code
            };

            //////////////// Api call //////////////
            var responce = await new ApiService().POST("/forgotKeyCheck", data);

            // console.log(responce.data);

            if (responce.data.status === "success") {
                if (responce.data.user_details.status === "1") {
                    toast.success("OTP verify successfully");
                    navigate("/forgot-new-password", { replace: true, state: { page_show_permission: true, userID: responce.data.user_details.id } });
                } else {
                    toast.error("Your account was deactivate, please contact admin");
                }
            } else {
                toast.error("something went wrong");
            }

            setLoader(false);
        }
        catch (err) {
            console.error(err);
            setLoader(false);
        }
    }




    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        ///////////// page_show_permission Check ///////////////
        if (location.state === null) {
            navigate(-1)
        } else {

            if (location.state.page_show_permission) {

                setEmail(location.state.email);

            } else {
                navigate(-1)
            }
        }
        ///////////// page_show_permission Check ///////////////

    }, [])


    return (
        <>

            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}
            {/* <Header /> */}

            <>
                {/*================================= banner */}
                <section
                    className="inner-intro bg bg-fixed bg-overlay-black-60"
                    style={{ backgroundImage: "url(images/bg/inner-bg-1.jpg)" }}
                >
                    <div className="container">
                        <div className="row intro-title text-center">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h1 className="position-relative divider">
                                        Verification Code
                                        <span className="sub-title">Verification Code</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-12 mt-7">
                                <ul className="page-breadcrumb">
                                    <li>
                                        <a href="index-default.html">
                                            <i className="fa fa-home" /> Home
                                        </a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <a href="contact-1.html">contact</a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <span>Verification Code</span>{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= banner */}
                {/*================================= Page Section */}
                <section
                    className="login-form login-img dark-bg page-section-ptb otp-generator "
                    style={{
                        background: "url(images/pattern/04.png) no-repeat 0 0",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="login-1-form clearfix text-center">
                                    <h4 className="title divider-3 text-white">
                                        Enter Verification Code{" "}
                                    </h4>
                                    <p className="des">
                                        We have sent the code for verification to your{" "}
                                        <span>Mobile </span> Number and your
                                        <span>Email</span>{" "}
                                    </p>
                                    
                                    <div className="section-field mb-3 my-md-5">

                                        <div className="otp-generator-wrap">

                                            <VerificationInput
                                                classNames={"otp-input"}
                                                placeholder={""}
                                                length={4}
                                                validChars={"0-9"}
                                                autoFocus={true}
                                                value={Code}
                                                onChange={setCode}
                                            />
                                        </div>





                                    </div>
                                    <p className="des" style={{fontSize: "12px"}}>
                                        Please check your spam in case the code does not come through your inbox! Thank you!
                                    </p>
                                    <div className="clearfix" />
                                    <div className="section-field text-uppercase text-center mt-2">

                                        <button type="button" onClick={submit} className="button  btn-lg btn-theme full-rounded animated right-icn">
                                            <span>
                                                Verify
                                                <i className="glyph-icon flaticon-hearts" aria-hidden="true" />
                                            </span>
                                        </button>


                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= page-section */}
            </>


            {/* <Footer /> */}
        </>
    )
}

export default memo(OtpVerify)