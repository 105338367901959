import React, { useCallback, useEffect, useState } from "react";
import AgoraRTC, {
  useRTCClient,
  useIsConnected,
  useClientEvent,
  LocalUser,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  RemoteUser,
  useRemoteUsers,
  useJoin,
  usePublish,
  LocalVideoTrack,
  AgoraRTCProvider,
} from "agora-rtc-react";
// import AgoraRTC from "agora-rtc-sdk-ng";

import { useStopwatch } from "react-timer-hook";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../../redux/auth";
import {
  ref,
  child,
  get,
  serverTimestamp,
  onChildChanged,
  update,
} from "firebase/database";
import { firebase_db } from "../../../FirebaseSetup";
import ApiService from "../../../services/ApiService";
import Swal from "sweetalert2";

const AgoraManager = ({ config, location }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData, userId } = useSelector((state) => state.auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const agoraEngine = useRTCClient();

  const navigate = useNavigate();
  const [MicMuted, setMicMuted] = useState(false);
  const [CameraOff, setCameraOff] = useState(false);
  const [Volumn, setVolumn] = useState(10);

  const { localCameraTrack } = useLocalCameraTrack();
  const { localMicrophoneTrack } = useLocalMicrophoneTrack();
  const isConnected = useIsConnected();

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  useJoin({
    appid: config.appId,
    channel: config.channel,
    token: config.token,
  });

  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUsers = useRemoteUsers();

  // // Join the channel automatically when the component loads
  // useEffect(() => {

  //   return () => {
  //     // Cleanup logic when the component unmounts
  //     // localCameraTrack?.close();
  //     // localMicrophoneTrack?.close();
  //   };
  // }, []); // Empty dependency array ensures it runs only once on component mount

  // Event listener for user left
  useClientEvent(agoraEngine, "user-left", (user) => {
    console.log("The user", user.uid, "has left the channel");
    // alert("user_left")
    CallEnd();
  });

  // Event listener for user published
  useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
    console.log("The user", user.uid, "has published media in the channel");
  });

  const toggleMic = () => {
    setMicMuted((prev) => !prev);
  };

  const Camera_Off_On = () => {
    setCameraOff((prev) => !prev);
  };

  const toggleSpeakerphone = useCallback(() => {
    if (Volumn === 10) {
      setVolumn(100);
    } else {
      setVolumn(10);
    }
  }, [Volumn]);

  const CallEnd = async () => {
    let new_data = {
      userid: location.state.snap_data.sender_id,
      call_duration: totalSeconds, // given value in second
      callerid: location.state.snap_data.receiver_id,
      genKey: location.state.room_id,
      call_type: "videocall", // video or audio
      login_user_id: userId
    };

    ///////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST(`/balance-deduct-2`, new_data);

    // alert("Video Call with  has ended. $ " + responce.data.balance_deduct + " has therefore been deducted from your Wallet.");

    ///// firebase update //////////////
    const updates = {};
    updates[
      `/user_video_audio_call_requests/${location.state.room_id}/status`
    ] = "complete";
    updates[
      `/user_video_audio_call_requests/${location.state.room_id}/created_at`
    ] = serverTimestamp();
    update(ref(firebase_db), updates);

    /////////////////// Api call ///////////////////////
    var responce_2 = await new ApiService().POST("/user-info", {
      userid: userData.id,
    });

    var resData = responce_2.data.user_details[0];
    // console.log("getUserDetails ", resData);
    dispatch(authActions.UpdateUserData(resData));

    Swal.fire(responce.data.message);

    console.log("call end");

    localCameraTrack?.close();
    localMicrophoneTrack?.close();

    navigate("/find-match");
    // window.location.replace("/find-match");
  };

  // Stop the stopwatch after 5 minutes
  useEffect(() => {
    if (totalSeconds >= Number(location.state.snap_data.totalSeconds)) {
      pause();
      CallEnd();
    }
    // if (totalSeconds >= (2 * 60)) {
    //     pause();
    //     CallEnd();
    // }
  }, [totalSeconds, pause]);

  return (
    <div className="video_call">
      {/* Video Grid for Remote Users */}
      {/* {remoteUsers.map((remoteUser) => (
                <div className="vid" style={{ height: 300, width: 600 }} key={remoteUser.uid}>
                <RemoteUser user={remoteUser} playVideo={true} playAudio={true} />
                </div>
            ))} */}

      {/* {remoteUsers.length} */}

      {/* Local Video */}
      {/* <div className="vid" style={{ height: 300, width: 600 }}>
                <LocalVideoTrack track={localCameraTrack} play={true} />
            </div> */}

      <div
        className="video_call_timer"
        style={{ fontSize: "30px", fontWeight: "800" }}
      >
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>

      {/* Video Grid for Remote Users */}
      {remoteUsers.map((user, index) => (
        <>
          {index === 0 && (
            <div className="remote_user" key={user.uid}>
              <RemoteUser
                user={user}
                audioTrack={user.audioTrack}
                videoTrack={user.videoTrack}
                playAudio={true}
                playVideo={true}
                volume={Volumn}
                className="remote_player"
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              />
            </div>
          )}
        </>
      ))}

      {/* Local Video */}
      {isConnected && (
        <>
          <div className="local_user">
            <LocalUser
              videoTrack={localCameraTrack}
              audioTrack={localMicrophoneTrack}
              cameraOn={!CameraOff}
              micOn={!MicMuted}
              playVideo={true}
              playAudio={false}
              className="local_player"
              cover={
                "https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
              }
            />
          </div>
        </>
      )}

      <div className="v-cal-btn-wrap">
        {/* Mute mic */}
        {/* <button onClick={toggleMic}>
          <i class="fa-solid fa-microphone"></i>
          {MicMuted ? "Unmute Mic" : "Mute Mic"}
        </button> */}
        <button onClick={toggleMic}>
          {MicMuted ? (
            <>
              <i className="fa-solid fa-microphone-slash"></i>
              <span>Mute On</span>
            </>
          ) : (
            <>
              <i class="fa-solid fa-microphone"></i>
              <span>Mute Off</span>
            </>
          )}
        </button>

        {/* Mute mic */}
        {/* <button onClick={Camera_Off_On}>
                <i class="fa-solid fa-camera"></i>
                    {CameraOff ? 'Camera On' : 'Camera Off'}
                </button> */}

        <button onClick={Camera_Off_On} className={CameraOff ? "off" : ""}>
          {CameraOff ? (
            <>
              <i class="fa-solid fa-video"></i>
              <span>Camera Off</span>
            </>
          ) : (
            <>
              <i class="fa-solid fa-video"></i>
              <span>Camera On</span>
            </>
          )}
        </button>

        {/* <button onClick={toggleSpeakerphone}>
          <i class="fa-solid fa-headphones"></i>
          {Volumn === 100 ? "Speaker Off" : "Speaker On"}
        </button> */}

        <button onClick={toggleSpeakerphone}>
          {Volumn === 100 ? (
            <>
              <i class="fa-solid fa-volume-high"></i>
              <span>Speaker On</span>
            </>
          ) : (
            <>
              {/* <i class="fa-solid fa-volume-off"></i> */}
              <img  src="/images/speaker_off_white.png" alt="" />
              <span>Speaker Off</span>
            </>
          )}
        </button>

        {/* End Call Button */}
        <button className="call-end" onClick={CallEnd}>
          <i class="fa-solid fa-phone-slash"></i>
          <span>End Video Call</span>
        </button>
        {/* Additional UI Components or Children */}
      </div>
    </div>
  );
};

// App Component
const Index = () => {
  const location = useLocation();

  const config = {
    // Your Agora App Config
    appId: "0feea1b1632c49cebb1b8aa53b7fdc00",
    channel: location.state.room_id,
    token: null,
  };

  const [client] = useState(() =>
    AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
  );

  return (
    <AgoraRTCProvider client={client}>
      <AgoraManager config={config} location={location} />
    </AgoraRTCProvider>
  );
};

export default Index;
