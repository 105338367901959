import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../PreLoader'
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import moment from "moment";

function WalletTransactions() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Items, setItems] = useState([]);


    const getList = useCallback(async () => {
        setLoader(true);
//alert("ok");
        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-wallet-chat-trans-history?searchFor=wallet&userId=${userData.id}`);
        console.log("get-wallet-chat-trans-history?searchFor=wallet ", responce.data);

        // return;

        if (responce.data.status === "1") {
            console.log(responce.data.data,"wallet transaction history")
            setItems(responce.data.data);
        }
        else {
            setItems([]);
        }

        setLoader(false);
    }, [userData.id]);


    useEffect(() => {
        getList();
    }, []);


    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="add-fund">
            <h2> Outstanding Wallet Balance </h2>
            <ul className="a-f-m">
                <li>${Number(userData.wallet_amount).toFixed(2)}</li>
            </ul>
        </div>

        <div className="connections">

            {Items.map((item, index) => (<>
                <div className="profile wallet-de" key={index} style={{ color: `${item.pay_for === "wallet" ? "green" : "red"}` }}>
                    <div className="details wallet-de-des" style={{ cursor: "auto" }}>
                        <h2>#{index + 1}</h2>
                        {/* <p>Software Engineer</p> */}
                        <p>{item.pay_for}</p>
                        <p>{moment.unix(item.pay_date).format("DD/MM/YYYY")}</p>
                        <p>{(item.pay_for === "audiocall" || item.pay_for === "videocall") && <>
                            Call Duration: {moment.utc(moment.duration(item.call_duration, 'seconds').asMilliseconds()).format("HH:mm:ss")}
                        </>}</p>
                    </div>
                    <div className="wallet-de-card">
                        <p>{item.pay_for === "wallet" ? <span style={{ color: "green" }}> Credit</span> : <span style={{ color: "red" }}> Debit</span>}   ${Number(item.pay_amount).toFixed(2)} </p>
                    </div>
                </div>
            </>))}


            {(Items.length === 0) && (<>
                <div className="profile text-center">
                    <div className="details">
                        <h2>No Data Found!</h2>
                    </div>

                </div>
            </>)}


        </div>

    </>)
}

export default memo(WalletTransactions);