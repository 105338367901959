import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../PreLoader'
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";
import moment from "moment";

function ChatPackagesTransactions() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Items, setItems] = useState([]);


    const getList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-wallet-chat-trans-history?searchFor=chat_subscription&userId=${userData.id}`);
        console.log("get-wallet-chat-trans-history?searchFor=wallet ", responce.data);

        // return;

        if (responce.data.status === "1") {
            setItems(responce.data.data);
        }
        else {
            setItems([]);
        }

        setLoader(false);
    }, [userData.id]);


    useEffect(() => {
        getList();
    }, []);


    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}


        <div className="connections">

            {Items.map((item, index) => (<>
                <div className="profile chat-package" key={index}>
                    <div className="details " style={{ cursor: "auto" }}>
                        <h2>#{index + 1}</h2>
                        {/* <p>Software Engineer</p> */}
                        <p>{moment.unix(item.pay_date).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="chat-price">
                        <p>{item.plan_name}</p>
                        <p>${item.pay_amount}</p>
                    </div>
                </div>
            </>))}


            {(Items.length === 0) && (<>
                <div className="profile text-center">
                    <div className="details">
                        <h2>No Data Found!</h2>
                    </div>

                </div>
            </>)}


        </div>

    </>)
}

export default memo(ChatPackagesTransactions);