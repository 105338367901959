import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import PreLoader from "../PreLoader";

function Index() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Items, setItems] = useState([]);

  const getConnectionList = useCallback(async () => {
    setLoader(true);

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().GET(
      `/connection-list?user_id=${userData.id}`
    );
    console.log("connection-list ", responce.data);

    // return;

    if (responce.data.status === "1") {
      setItems(responce.data.data);
    }

    setLoader(false);
  }, []);

  useEffect(() => {
    getConnectionList();
  }, []);

  return (
    <>
      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}
      <div className="connections">
        <h1>My Connections</h1>

        {Items.map((item, index) => (<>
          <div className="profile" key={index}
            onClick={() => navigate("/view-profile", {
              state: {
                profile_id: item.connection_user_id,
                page_show_permission: true
              }
            })}
          >
            <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" />
            <div className="details">
              <p>{item.login_status == "0" ? (<span className="offline">Offline</span>) : (<span className="online">Online</span>)}</p>
              <h2>{item.name}</h2>
              {(item?.gender) && <h5>Gender: {item?.gender}</h5>}
              {(item?.distancedata_2?.miles) && <h5>Distance: {item?.distancedata_2?.miles} Miles</h5>}
              {(item?.matchpercent) && <h5>Match: {item?.matchpercent}%</h5>}
              {/* <Link to={"/view-profile"}
                state={{
                  profile_id: item.connection_user_id,
                  page_show_permission: true
                }}
                style={
                  {
                    textDecoration: "none",
                    color: "#8abb45",
                    fontSize: "16px",
                    fontWeight: "600",
                    letterSpacing: "0.5px",
                    lineHeight: "24px",
                  }
                }
              >View Profile</Link> */}
            </div>
            {/* <div className="icons">
              <img
                className="img-fluid me-2"
                src="/images/play-icon.png"
                alt=""
              />
              <img
                className="img-fluid me-2"
                src="/images/play-icon.png"
                alt=""
              />
              <img
                className="img-fluid me-2"
                src="/images/play-icon.png"
                alt=""
              />
            </div> */}
          </div>
        </>))}


        {(Items.length === 0) && (<>
          <div className="profile">
            <div className="details">
              <h2>No Data Found!</h2>
            </div>

          </div>
        </>)}


      </div>
    </>
  );
}

export default memo(Index);
