// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// // Your web app's Firebase configuration 1
// const firebaseConfig = {
//   apiKey: "AIzaSyBhNIhYYIVk8q7PEykwQIjAs9Bayq2GbXg",
//   authDomain: "new-test-chat-app.firebaseapp.com",
//   projectId: "new-test-chat-app",
//   storageBucket: "new-test-chat-app.appspot.com",
//   messagingSenderId: "978322286851",
//   appId: "1:978322286851:web:92fdb17b2b48a391a26136",
//   databaseURL:"https://new-test-chat-app-default-rtdb.firebaseio.com/"
// };


// // Your web app's Firebase configuration 2
const firebaseConfig = {
  apiKey: "AIzaSyD8q1xLcZ4qfS1A2mT_D2yr00Q6g9-DiuQ",
  authDomain: "newrealtimevillage.firebaseapp.com",
  databaseURL: "https://newrealtimevillage-default-rtdb.firebaseio.com",
  projectId: "newrealtimevillage",
  storageBucket: "newrealtimevillage.appspot.com",
  messagingSenderId: "220299492038",
  appId: "1:220299492038:web:337fef409c9eae5bf475c7",
  measurementId: "G-KTN2EVQQS4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebase_auth=getAuth(app);
export const firebase_db=getDatabase(app);