// import loading from "/images/loading.gif";
// import home_logo from "/images/home-logo.png";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <>
      <footer className=" text-white text-center">
        {/* <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <div className="row mb-4 mb-md-5">
                                <div className="col-md-12">
                                    <h2 className="title divider mb-3">Contact Us</h2>
                                    <p className="lead">Eum cu tantas legere complectitur, hinc utamur ea eam. Eum patrioque mnesarchum eu, diam erant convenire et vis. Et essent evertitur sea, vis cu ubique referrentur, sed eu dicant expetendis. Eum cu</p>
                                </div>
                            </div>
                            <div className="row mb-4 mb-md-5">
                                <div className="col-md-12 text-center">
                                    <div className="address-block"> <i className="fa fa-desktop" aria-hidden="true" /> <a href="mailto:somemail@mail.com">somemail@mail.com</a> </div>
                                </div>

                               

                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    
                                </div>
                            </div>
                            <div className="row mb-0 mb-md-2 mb-lg-3 mb-xl-4">
                                <div className="col-md-12 mb-0 mb-lg-2">
                                    <div id="formmessage" style={{ display: 'none' }}>Success/Error Message Goes Here</div>
                                    <form id="contactform" className="main-form" method="post" action="https://themes.potenzaglobalsolutions.com/html/cupid-love-dating-website-html5-template/cupid-love/php/contact-form.php">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <div className="input-group">
                                                    <input id="name" placeholder="Your name here" className="form-control" name="name" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="input-group">
                                                    <input placeholder="Your mail here" className="form-control" name="email" type="email" />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <div className="input-group">
                                                    <textarea className="form-control input-message" placeholder="Your message here*" rows={7} name="message" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-0">
                                                <input type="hidden" name="action" defaultValue="sendEmail" />
                                                <button id="submit" name="submit" type="submit" value="Send" className="button btn-lg btn-theme full-rounded animated right-icn mb-0"><span>Submit Now<i className="glyph-icon flaticon-hearts" aria-hidden="true" /></span></button>
                                            </div>
                                        </div>
                                    </form>
                                    <div id="ajaxloader" style={{ display: 'none' }}><img className="center-block" src="/images/loading.gif" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="footer-widget">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                {!isAuthenticated && (
                  <>
                    <div className="footer-logo mb-2">
                      {" "}
                      <img
                        className="img-center"
                        src="/images/home-logo.png"
                        alt="home-logo"
                      />{" "}
                    </div>
                    <div className="footer-list">
                      <ul>
                        <li>
                          <Link to="/product-services">
                            PRODUCTS & SERVICES
                          </Link>
                        </li>
                        <li>
                          <Link to="/return-refund-policy">
                            RETURN & REFUND POLICY
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">PRIVACY POLICY</Link>
                        </li>
                        <li>
                          <Link to="/terms-condition">TERMS & CONDITIONS</Link>
                        </li>
                        {/* <li><a href="#">FAQ</a></li> */}
                        <li>
                          <Link to="/disclaimer">DISCLAIMER</Link>
                        </li>
                      </ul>
                    </div>
                  
                      <div className="app-wraper">
                        <div>
                          <a href="https://play.google.com/store/apps/details?id=com.realtimevillageandroid">
                            <img src="/images/googlepay-btn.png" alt="app" />
                          </a>
                        </div>
                        <div>
                          <a href="https://play.google.com/store/apps/details?id=com.realtimevillageandroid&pcampaignid=web_share">
                            <img src="/images/app-store.png" alt="app" />
                          </a>
                        </div>
                      </div>
                 
                    <div className="social-icons color-hover">
                      <ul>
                        <li className="social-facebook">
                          <a href="#">
                            <i class="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="social-dribbble">
                          <a href="#">
                            <i class="fa-brands fa-instagram"></i>
                          </a>
                        </li>
                        <li className="social-twitter">
                          <a href="#">
                            <i class="fa-brands fa-x-twitter"></i>
                          </a>
                        </li>
                        <li className="social-gplus">
                          <a href="#">
                            <i class="fa-brands fa-youtube"></i>
                          </a>
                        </li>
                        <li className="social-youtube">
                          <a href="#">
                            <i class="fa-brands fa-whatsapp"></i>
                          </a>
                        </li>
                        <li className="social-youtube">
                          <a href="#">
                            <i class="fa-brands fa-tiktok"></i>
                          </a>
                        </li>
                        <li className="social-youtube">
                          <a href="#">
                            <i class="fa-brands fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                  </>
                )}

                <p className="text-white">Copyright © 2024 Real Time Village</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/*================================= Back to Top */}
      <div id="back-to-top">
        <a className="top arrow" href="#top">
          <i className="fa fa-level-up" />
        </a>
      </div>
    </>
  );
}

export default Footer;
