import * as yup from "yup";

// const phoneRegExps = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validImageFileExtensions = { image: ['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp'] };

function isValidImageFileType(fileName, fileType) {
  console.log(fileName);
  return fileName && validImageFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
}

const MAX_IMAGE_FILE_SIZE = (4 * 1024 * 1024); // 4 mb
const MAX_ADDITIONAL_IMAGE_FILE_SIZE = (2.5 * 1024 * 1024); // 2.5 mb

export const RegistrationSchema = yup.object().shape({

  email: yup.string().required("email required").email(),

  // telephone: yup.string().required("phone number required").min(10,"phone number min length should be in 10 digit").max(10,"phone number max length should be in 10 digit").matches(phoneRegExps, 'Phone number is not valid'),

  // telephone_code: yup.string().required("telephone code Required"),

  pass: yup.string().required("password required").min(8, "password min length should be in 8 characters"),

  conpass: yup.string().required("confirm password required").oneOf([yup.ref("pass")], "confirm password does not match password"),

})


export const LoginSchama = yup.object({
  email: yup.string().required().email(),
  pass: yup.string().required()
});


export const UserProfileSetupSchama = yup.object({

  name: yup.string().required("name is required"),
  age: yup.number("age must be a number").min(18, 'age must be at least 18').required('age is required'),
  country: yup.string().required("country is required"),
  city: yup.string().required("city is required"),
  zipcode: yup.string().required("zip code is required"),
  gender: yup.string().required("gender is required"),
  occupation: yup.string().required("occupation is required"),
  marital: yup.string().required("marital is required"),
  education: yup.string().required("education is required"),
  height: yup.string().required("height is required"),
  bodyType: yup.string().required("body type is required"),
  profile_pic: yup.mixed().required('profile picture is required')
    .test("is-valid-type", "Not a valid image type", value => isValidImageFileType(value && value.name.toLowerCase(), "image"))
    .test("is-valid-size", "Max allowed size is 4mb", value => value && value.size <= MAX_IMAGE_FILE_SIZE)
});


export const MatchPreferencesSchama = yup.object({

  age: yup.number("age must be a number").min(18, 'age must be at least 18').required('age is required'),
  country: yup.string().required("country is required"),
  city: yup.string().required("city is required"),
  zipcode: yup.string().required("zip code is required"),
  gender: yup.string().required("gender is required"),
  occupation: yup.string().required("occupation is required"),
  marital: yup.string().required("marital is required"),
  education: yup.string().required("education is required"),
  height: yup.string().required("height is required"),
  bodyType: yup.string().required("body type is required"),

});


export const UpdateUserProfileSetupSchama = yup.object({

  name: yup.string().required("name is required"),
  email: yup.string().required("email required").email(),
  age: yup.number("age must be a number").min(18, 'age must be at least 18').required('age is required'),
  country: yup.string().required("country is required"),
  city: yup.string().required("city is required"),
  zipcode: yup.string().required("zip code is required"),
  gender: yup.string().required("gender is required"),
  occupation: yup.string().required("occupation is required"),
  marital: yup.string().required("marital is required"),
  education: yup.string().required("education is required"),
  height: yup.number().required("height is required"),
  bodyType: yup.string().required("body type is required")
});


export const UpdateUserProfileImageSetupSchama = yup.object({
  profile_pic: yup.mixed().required('profile picture is required')
    .test("is-valid-size", "Max allowed size is 4mb", value => value && value.size <= MAX_IMAGE_FILE_SIZE)
    .test("is-valid-type", "Not a valid image type", value => isValidImageFileType(value && value.name.toLowerCase(), "image"))
});


export const ChangePasswordSchama = yup.object({
  old_password: yup.string().required("old password required"),
  new_password: yup.string().required("new password required").min(8, "new password min length should be in 8 characters"),
  confirm_password: yup.string().required("confirm password required").oneOf([yup.ref("new_password")], "confirm password does not match new password"),
});

export const AdditionalImageUploadSchama = yup.object({
  profile_pic: yup.mixed().required('additional picture is required')
    .test("is-valid-size", "Max allowed size is 2.5", value => value && value.size <= MAX_ADDITIONAL_IMAGE_FILE_SIZE)
    .test("is-valid-type", "Not a valid image type", value => isValidImageFileType(value && value.name.toLowerCase(), "image")),
});


/////// Stripe Card Schema //////////////////
export const StripeCardSchema = yup.object({
  cardNumber: yup.string().matches(/^\d{16}$/, 'Invalid card number').required('Card number is required'),
  cardHolder: yup.string().required('Cardholder name is required'),
  expiry: yup.string().matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiry date').required('Expiry date is required'),
  cvc: yup.string().matches(/^\d{3}$/, 'Invalid CVC').required('CVC is required'),
});


export const ReportSchama = yup.object({
  report_reason: yup.string().required("report comment is required")
});



///////////////////////////////////////////////////////////////////////////////////////////
// ================ old ================================================================ //
///////////////////////////////////////////////////////////////////////////////////////////
export const QuestionSchama = yup.object({

  lstModule: yup.string().required("Required"),
  lstSubject: yup.string().required("Required"),
  lstNum: yup.string().required("Required"),
  chkRandom: yup.string().required("check random Required"),
  chkHide: yup.string().required("Check Required"),
  radMode: yup.string().required("Radmode Required"),


})

export const referencesSchema = yup.object({
  first_reference: yup.string().required("First Reference Required"),
  second_reference: yup.string().required("Second Reference Required"),
  third_reference: yup.string().required("Third Reference Required")
});
export const medicalSchema = yup.object({
  mmr_vaccine: yup.string().required("Vaccine Required"),
  hepatitis_vaccine: yup.string().required("Hepatities Required"),

});

export const licenseDataSchema = yup.object({
  national_license_issue_date: yup.string().required("National License Issue Date Require"),
  national_license_expiry_date: yup.string().required("National License Expiry Date Require"),
  //   national_license_file:yup.mixed().test("required", "Please select a file", value => {
  //         return value && value.length;
  //     }).test({
  //             message: 'Please provide a supported file type (pdf,doc,docx)',
  //             test: (file, context) => {
  //                 const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //                 if (!isValid) context?.createError();
  //                 return isValid;
  //             }
  //         }),
  state_license_issue_date: yup.string().required("State License Issue Date Require"),
  state_license_expiry_date: yup.string().required("State License Expiry Date Require"),
  //   state_license_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (pdf,doc,docx)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),
  cds_license_issue_date: yup.string().required("CDS License Issue Date Require"),
  cds_license_expiry_date: yup.string().required("CDS License Expiry Date Require"),
  //   cds_license_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (pdf,doc,docx)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),
  dea_license_issue_date: yup.string().required("DEA License Issue Date Require"),
  dea_license_expiry_date: yup.string().required("DEA License Expiry Date Require"),
  //   dea_license_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (pdf,doc,docx)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),
  // licenseInfo:yup.array(yup.object({
  //   license_name:yup.string().required("License name Required"),
  //   license_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (doc,docx,pdf)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),
  //     license_issue_date:yup.string().required("License issue Date Required"),
  //     license_expiry_date:yup.string().required("License end Date Required"),

  // })),
});


// export const licenseSchema=yup.object({
// licenseInfo:yup.array(yup.object({
//   license_name:yup.string().required("License name Required"),
//   additional_license:yup.mixed().test("required", "Please select a file", value => {
//     return value && value.length;
// }).test({
//         message: 'Please provide a supported file type (png,jpg,jpeg)',
//         test: (file, context) => {
//             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
//             if (!isValid) context?.createError();
//             return isValid;
//         }
//     }),
//    license_issue_date:yup.string().required("License issue Date Required"),
// license_expiry_date:yup.string().required("License end Date Required"),

// })),

// });
export const CertificationSchema = yup.object({
  bls_issue_date: yup.string().required("BLS Certificate Issue Date Require"),
  bls_expiry_date: yup.string().required("BLS Certificate Expiry Date Require"),
  //   bls_file:yup.mixed().test("required", "Please select a file", value => {
  //         return value && value.length;
  //     }).test({
  //             message: 'Please provide a supported file type (pdf,doc,docx)',
  //             test: (file, context) => {
  //                 const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //                 if (!isValid) context?.createError();
  //                 return isValid;
  //             }
  //         }),
  acls_issue_date: yup.string().required("ACLS Certificate Issue Date Require"),
  acls_expiry_date: yup.string().required("ACLS Certificate Expiry Date Require"),
  //         acls_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (pdf,doc,docx)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),
  pls_issue_date: yup.string().required("PLS Certificate Issue Date Require"),
  pls_expiry_date: yup.string().required("PLS Certificate Expiry Date Require"),
  //     pls_file:yup.mixed().test("required", "Please select a file", value => {
  //     return value && value.length;
  // }).test({
  //         message: 'Please provide a supported file type (pdf,doc,docx)',
  //         test: (file, context) => {
  //             const isValid = ['pdf', 'doc', 'docx'].includes(getExtension(file[0]?.name));
  //             if (!isValid) context?.createError();
  //             return isValid;
  //         }
  //     }),

});
export const CurrentEmployeSchema = yup.object({
  job_name: yup.string().required("Job Name Required"),
  job_type: yup.string().required("Job Type Required"),
});
export const educationSchema = yup.object({
  ug_institute_name: yup.string().required("Name Required"),
  g_institute_name: yup.string().required("Name Required"),
  med_institue_name: yup.string().required('name required'),
  ug_from_year: yup.string().required("choose date"),
  ug_to_year: yup.string().required("choose date"),
  g_from_year: yup.string().required("choose date"),
  g_to_year: yup.string().required("choose date"),
  med_school_from_year: yup.string().required("choose date"),
  med_school_to_year: yup.string().required("choose date"),
});


// function getExtension(path) {

//   if (path !== undefined) {

//       var basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
//           // (supports `\\` and `/` separators)
//           pos = basename.lastIndexOf(".");       // get last position of `.`

//       if (basename === "" || pos < 1)            // if file name is empty or ...
//           return "";                             //  `.` not found (-1) or comes first (0)

//       return basename.slice(pos + 1);            // extract extension ignoring `.`
//   } else {
//       return "";
//   }
// }


// ================================================ //
//       new schema                                 //
// ================================================ //