import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PreLoader from "../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdditionalImageUploadSchama } from "../../Schemas";
import ApiService from "../../services/ApiService";
import { toast } from "react-toastify";

function AddPhotos() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { userData } = useSelector((state) => state.auth);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  const imgRef = useRef();

  // /////////////// Form value ////////////////////
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    // getValues,
    // trigger,
    // control,
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({
    resolver: yupResolver(AdditionalImageUploadSchama),
    mode: "all",
  });


  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Images, setImages] = useState({
    img_1: {
      url: "/images/step/01.png",
      demo_url: "/images/step/01.png",
      id: null,
    },
    img_2: {
      url: "/images/step/02.png",
      demo_url: "/images/step/02.png",
      id: null,
    },
    img_3: {
      url: "/images/step/03.png",
      demo_url: "/images/step/03.png",
      id: null,
    },
    img_4: {
      url: "/images/step/04.png",
      demo_url: "/images/step/04.png",
      id: null,
    },
    img_5: {
      url: "/images/step/05.png",
      demo_url: "/images/step/05.png",
      id: null,
    },
    img_6: {
      url: "/images/step/06.png",
      demo_url: "/images/step/06.png",
      id: null,
    },
  });

  const getAdditionalImages = async () => {
    setLoader(true);

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST("/get-additional-profilepic", {
      userid: userData.id,
    });

    console.log("get-additional-profilepic ", responce.data)

    if (responce.data.status === "1") {
      var resData = responce.data.imageData;
      // console.log("getPreferenceDetails ", resData);

      let oldData = Images;

      oldData.img_1.url = resData.img_1.url !== null ? resData.img_1.url : oldData.img_1.demo_url;
      oldData.img_1.id = resData.img_1.id;

      oldData.img_2.url = resData.img_2.url !== null ? resData.img_2.url : oldData.img_2.demo_url;
      oldData.img_2.id = resData.img_2.id;

      oldData.img_3.url = resData.img_3.url !== null ? resData.img_3.url : oldData.img_3.demo_url;
      oldData.img_3.id = resData.img_3.id;

      oldData.img_4.url = resData.img_4.url !== null ? resData.img_4.url : oldData.img_4.demo_url;
      oldData.img_4.id = resData.img_4.id;

      oldData.img_5.url = resData.img_5.url !== null ? resData.img_5.url : oldData.img_5.demo_url;
      oldData.img_5.id = resData.img_5.id;

      oldData.img_6.url = resData.img_6.url !== null ? resData.img_6.url : oldData.img_6.demo_url;
      oldData.img_6.id = resData.img_6.id;


      setImages(oldData);
     
    } else {
      console.log(responce.data);
    }

    setLoader(false);
  };

  const onSubmit = async (file_name, data) => {
    // console.log(data)
    setLoader(true);

    /////////////// img checking code ///////////
    let old_img = Images;
    var preImgId = null;
    for (const item in old_img) {
      // console.log(`${item}: ${old_img[item]}`);
      if (file_name === item) {
        if (old_img[item].id !== null) {
          preImgId = old_img[item].id;
          break;
        }
      }
    }

    // if (preImgId !== null) {
    //   await deleteImg(preImgId)
    // }


    /////////// img upload code ///////////
    var formData = new FormData();
    // formData.append("profile_pic", data.profile_pic);
    formData.append("profile_pic", data);
    formData.append("userid", userData.id);

    if (preImgId !== null) {
      formData.append("id", preImgId);
    }

    /////////////////// Api call ///////////////////////
    var responce = await new ApiService().POST(
      "/upload-additional-profilepic",
      formData,
      "file"
    );

    console.log(responce.data);

    await getAdditionalImages();
    reset();
    // imgRef.current.value = "";

    setLoader(false);
  };


  const uploadPreviewImage = (e) => {
    const file = e.target.files[0];
    const file_name = e.target.name;
    // alert(file_name);
    // return

    if (file) {
      // Display the preview of the image
      const reader = new FileReader();
      reader.onloadend = () => {
        // setPreviewImage(reader.result);
        setValue("profile_pic", file);

        onSubmit(file_name, file)
      };
      reader.readAsDataURL(file);
    } else {
      // setPreviewImage("/images/demo-one.png");
      setValue("profile_pic", null);
    }
  };

  useEffect(() => {
    getAdditionalImages();
  }, []);

  return (
    <>
      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}

      <div className="add-photos">
        <div className="row  justify-content-center">
          <div className="col-md-8">
            <div className="form-group mb-3">
              <div className="row">

                <div className="col-md-8">

                  <div className="ad-p-container">
                    {/* {Images.img_1.id !== null && (
                      <button
                        type="button"
                        onClick={() => deleteImg(Images.img_1.id)}
                      >
                        Del img 1
                      </button>
                    )} */}
                    <img
                      className="img-center w-100"
                      src={Images.img_1.url}
                      alt=""
                    />{" "}
                    <div className="ad-ph-overlay">
                      <div className="ad-ph-wrap">
                        <div>
                          <label className="add-upload-photos">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                            <input
                              type="file"
                              name="img_1"
                              class="form-control add-p-upload "
                              accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                              onChange={uploadPreviewImage}
                            ></input>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-md-4">

                  <div className="row">
                    <div className="col-md-12 mb-3 sm-mb-2">
                      <div className="ad-p-container">
                        {/* {Images.img_2.id !== null && (
                          <button
                            type="button"
                            onClick={() => deleteImg(Images.img_2.id)}
                          >
                            Del img 2
                          </button>
                        )} */}
                        <img
                          className="img-center"
                          src={Images.img_2.url}
                          alt=""
                        />
                        <div className="ad-ph-overlay">
                          <div className="ad-ph-wrap">
                            <div>
                              <label className="add-upload-photos">
                                <i class="fa fa-camera" aria-hidden="true"></i>
                                <input
                                  type="file"
                                  name="img_2"
                                  class="form-control add-p-upload "
                                  accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                                  onChange={uploadPreviewImage}
                                ></input>
                              </label>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-2">
                      <div className="ad-p-container">
                        {" "}
                        <img
                          className="img-center"
                          src={Images.img_3.url}
                          alt=""
                        />{" "}
                        <div className="ad-ph-overlay">
                          <div className="ad-ph-wrap">
                            <div>
                              <label className="add-upload-photos">
                                <i class="fa fa-camera" aria-hidden="true"></i>
                                <input
                                  type="file"
                                  name="img_3"
                                  class="form-control add-p-upload "
                                  accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                                  onChange={uploadPreviewImage}
                                ></input>
                              </label>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="ad-p-container">
                    {" "}
                    <img
                      className="img-center"
                      src={Images.img_4.url}
                      alt=""
                    />{" "}
                    <div className="ad-ph-overlay">
                      <div className="ad-ph-wrap">
                        <div>
                          <label className="add-upload-photos">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                            <input
                              type="file"
                              name="img_4"
                              class="form-control add-p-upload "
                              accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                              onChange={uploadPreviewImage}
                            ></input>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ad-p-container">
                    {" "}
                    <img
                      className="img-center"
                      src={Images.img_5.url}
                      alt=""
                    />{" "}
                    <div className="ad-ph-overlay">
                      <div className="ad-ph-wrap">
                        <div>
                          <label className="add-upload-photos">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                            <input
                              type="file"
                              name="img_5"
                              class="form-control add-p-upload "
                              accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                              onChange={uploadPreviewImage}
                            ></input>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ad-p-container">
                    {" "}
                    <img
                      className="img-center"
                      src={Images.img_6.url}
                      alt=""
                    />{" "}
                    <div className="ad-ph-overlay">
                      <div className="ad-ph-wrap">
                        <div>
                          <label className="add-upload-photos">
                            <i class="fa fa-camera" aria-hidden="true"></i>
                            <input
                              type="file"
                              name="img_6"
                              class="form-control add-p-upload "
                              accept=".jpg,.gif,.png,.jpeg,.svg,.webp"
                              onChange={uploadPreviewImage}
                            ></input>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPhotos;
