import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Stripe from './Stripe';
import ApiService from '../../services/ApiService';
import { useSelector } from "react-redux";

function Index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    const location = useLocation();

    const [StripePublishKey, setStripePublishKey] = useState(null);
    const [ClientSecret, setClientSecret] = useState(null);

    const getAllPaymentGetWayKey = async () => {
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/get-credential-keys/stripe`);
        console.log("get-credential-keys/stripe ", response.data);

        if (response.data.status === '1') {
            setStripePublishKey(response.data.client_id);
        }


        const payload = {
            userid: userData.id,
            amount: location.state.amount
        }

        // Create the PaymentIntent and obtain clientSecret from your server endpoint
        /////////////////// Api call ///////////////////////
        const response_2 = await new ApiService().POST(`/create-stripe-payment-intent`, payload);
        console.log("create-stripe-payment-intent ", response_2.data);


        if (response_2.data.status === '1') {
            setClientSecret(response_2.data.clientSecret)
        }
        else if (response_2.data.status === '2') {
            console.log(response_2.data);
            // setLoader(false);
            return;
        }
        else if (response_2.data.status === 'error') {
            console.log(response_2.data)
            // setLoader(false);
            return;
        }
    };

    useEffect(() => {
        getAllPaymentGetWayKey();
    }, [])


    return (<>

        {/* //////////// stripe ////////////// */}
        {(ClientSecret !== null) && <Stripe
            payment_type={location.state.payment_type}
            amount={location.state.amount}
            StripePublishKey={StripePublishKey}
            ClientSecret={ClientSecret}
            chat_plan_id={location.state?.chat_plan_id ? location.state.chat_plan_id : ""} />}
    </>)
}

export default Index