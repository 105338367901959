import axios from 'axios';
const REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;



class ApiService {

    // constructor(){
    //     this.token=localStorage.getItem("token");
    // }


    GET(url) {

        const headers = {
            "Content-Type": "application/json",
            // "Authorization": 'Bearer ' + this.token,
        }

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: finalUrl,
            headers: headers,
        };

        return axios(config);
    }



    POST(url, payload, h_tag = "json") {

        const headers = {
            "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
            // "Authorization": 'Bearer ' + this.token,
        }

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: finalUrl,
            headers: headers,
            data: payload
        };

        return axios(config);
    }



    PUT(url, payload, h_tag = "json") {

        const headers = {
            "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
            // "Authorization": 'Bearer ' + this.token,
        }

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: finalUrl,
            headers: headers,
            data: payload
        };

        return axios(config);
    }


    DELETE(url, payload, h_tag = "json") {

        const headers = {
            "Content-Type": (h_tag === "json" ? "application/json" : "multipart/form-data"),
            // "Authorization": 'Bearer ' + this.token,
        }

        var finalUrl = REACT_APP_API_SERVICE_URL + url;

        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: finalUrl,
            headers: headers,
            data: payload
        };


        return axios(config);
    }

}

export default ApiService;
