import { NavLink } from "react-router-dom"


function NotFound() {
    return (
        <>
            {/*================================= Page Section*/}
            <section className="page-section-ptb text-center clearfix error-page position-relative">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-lg-7 col-md-12">
                            <div className="big-title">404</div>
                            <h3 className="title mb-4 sm-mb-3">
                                <b>This is Awkward</b>
                            </h3>
                            <p className="lead">
                                Think back over your life. Think about the people that had a
                                positive influence on you. If your past was like mine, many of them
                                didn’t realize the impact they made. The influence was usually due
                                to them caring about you and doing some little thing. What little
                                things have been done for you that changed your life? What little
                                things have you done for someone else that might have changed
                                theirs?
                            </p>
                            <NavLink to={"/"} className="button btn-lg btn-theme full-rounded animated right-icn mt-4">
                                <span>
                                    Go to Home page
                                    <i className="glyph-icon flaticon-hearts" aria-hidden="true" />
                                </span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
            {/*================================= Page Section*/}
        </>

    )
}

export default NotFound