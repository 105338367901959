import 'owl.carousel/dist/assets/owl.carousel.css';


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RequireAuth, WithOutAuth, CallStateCheck } from './Middleware';


import BasePage from "./pages/BasePage";
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ForgotOTP from "./pages/Auth/ForgotPassword/OtpVerify";
import NewPassword from "./pages/Auth/ForgotPassword/NewPassword";
import OTP from "./pages/Auth/OTP";
import AboutUs from "./pages/AboutUs";

import Contact from "./pages/Contact";
import FindMatch from "./pages/FindMatch";
import UserProfile from "./pages/UserProfile";
// import Stories from "./pages/Stories";
import NotFound from './pages/NotFound';

import Identification from "./pages/Auth/Identification";
import Preferences from './pages/Auth/Preferences';
import ViewProfile from './pages/ViewProfile';
import Chat from './pages/Chat';
import VideoCall from './pages/Call/VideoCall';
import AudioCall from './pages/Call/AudioCall';
import Payment from './pages/Payment';
import PaymentStatus from './pages/Payment/PaymentStatus';
// import DemoVideo from './pages/Call/VideoCall/DemoVideo';
import Blogs from './pages/Blogs';
import Connections from './pages/Connections';
import Notification from './pages/Notification';
import Gallery from './pages/Gallery';
import Disclaimer from './pages/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProductServices from './pages/ProductServices';
import ReturnRefundPolicy from './pages/ReturnRefundPolicy';
import TermsCondition from './pages/TermsCondition';
import ScrollToTop from './pages/ScrollToTop';




function App() {


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <BrowserRouter>

        {/* ScrollToTop component */}
        <ScrollToTop />

        <Routes>

          {/* ////////////////////// WithOutAuth middleware//////////////////////////// */}
          <Route path='/' element={<WithOutAuth><Home /></WithOutAuth>} />

          <Route path='' element={<BasePage />}>


            {/* ////////////////////// WithOutAuth middleware//////////////////////////// */}
            {/* ========== Auth =========== */}
            <Route path='login' element={<WithOutAuth><Login /></WithOutAuth>} />
            <Route path='register' element={<WithOutAuth><Register /></WithOutAuth>} />
            <Route path='otp' element={<WithOutAuth><OTP /></WithOutAuth>} />
            <Route path='identification' element={<Identification />} />
            <Route path='preferences' element={<Preferences />} />
            <Route path='about-us' element={<WithOutAuth><AboutUs /></WithOutAuth>} />

            <Route path='forgot-password' element={<WithOutAuth><ForgotPassword /></WithOutAuth>} />
            <Route path='forgot-otp' element={<WithOutAuth><ForgotOTP /></WithOutAuth>} />
            <Route path='forgot-new-password' element={<WithOutAuth><NewPassword /></WithOutAuth>} />

            <Route path='disclaimer' element={<Disclaimer />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='product-services' element={<ProductServices />} />
            <Route path='return-refund-policy' element={<ReturnRefundPolicy />} />
            <Route path='terms-condition' element={<TermsCondition />} />
            {/* ========== End Auth =========== */}

            <Route path='contact' element={<Contact />} />
            <Route path='blogs' element={<Blogs />} />
            {/* <Route path='stories' element={<WithOutAuth><Stories /></WithOutAuth>} /> */}



            {/* ////////////////////// RequireAuth middleware//////////////////////////// */}
            <Route path='find-match' element={<RequireAuth><FindMatch /></RequireAuth>} />
            <Route path='profile' element={<RequireAuth><UserProfile /></RequireAuth>} />
            <Route path='view-profile' element={<RequireAuth><ViewProfile /></RequireAuth>} />
            <Route path='chat' element={<RequireAuth><Chat /></RequireAuth>} />
            <Route path='payment' element={<RequireAuth><Payment /></RequireAuth>} />
            <Route path='payment-status' element={<RequireAuth><PaymentStatus /></RequireAuth>} />
            <Route path='connections' element={<RequireAuth><Connections /></RequireAuth>} />
            <Route path='notification' element={<RequireAuth><Notification /></RequireAuth>} />
            <Route path='gallery' element={<RequireAuth><Gallery /></RequireAuth>} />

            <Route path='video-call' element={<RequireAuth><CallStateCheck><VideoCall /></CallStateCheck></RequireAuth>} />
            <Route path='audio-call' element={<RequireAuth><CallStateCheck><AudioCall /></CallStateCheck></RequireAuth>} />

            {/* <Route path='vc' element={<DemoVideo />} /> */}



            {/* ======== Not Found page (404) ==== */}
            <Route path='*' element={<NotFound />} />

          </Route>


        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
