import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import ApiService from "../../services/ApiService";
import PreLoader from "../PreLoader";
import { useLocation, useNavigate } from "react-router-dom";

function Index() {

    const location = useLocation();
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);


    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Images, setImages] = useState({
        img_1: {
            url: "/images/step/01.png",
            demo_url: "/images/step/01.png",
            id: null,
        },
        img_2: {
            url: "/images/step/02.png",
            demo_url: "/images/step/02.png",
            id: null,
        },
        img_3: {
            url: "/images/step/03.png",
            demo_url: "/images/step/03.png",
            id: null,
        },
        img_4: {
            url: "/images/step/04.png",
            demo_url: "/images/step/04.png",
            id: null,
        },
        img_5: {
            url: "/images/step/05.png",
            demo_url: "/images/step/05.png",
            id: null,
        },
        img_6: {
            url: "/images/step/06.png",
            demo_url: "/images/step/06.png",
            id: null,
        },
    });



    const getUserAdditionalImages = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/get-additional-profilepic", {
            userid: location.state.id,
        });

        console.log("get-additional-profilepic ", responce.data)

        if (responce.data.status === "1") {
            var resData = responce.data.imageData;
            // console.log("getPreferenceDetails ", resData);

            let oldData = Images;
            oldData.img_1.url = resData.img_1.url !== null ? resData.img_1.url : oldData.img_1.demo_url;
            oldData.img_1.id = resData.img_1.id;

            oldData.img_2.url = resData.img_2.url !== null ? resData.img_2.url : oldData.img_2.demo_url;
            oldData.img_2.id = resData.img_2.id;

            oldData.img_3.url = resData.img_3.url !== null ? resData.img_3.url : oldData.img_3.demo_url;
            oldData.img_3.id = resData.img_3.id;

            oldData.img_4.url = resData.img_4.url !== null ? resData.img_4.url : oldData.img_4.demo_url;
            oldData.img_4.id = resData.img_4.id;

            oldData.img_5.url = resData.img_5.url !== null ? resData.img_5.url : oldData.img_5.demo_url;
            oldData.img_5.id = resData.img_5.id;

            oldData.img_6.url = resData.img_6.url !== null ? resData.img_6.url : oldData.img_6.demo_url;
            oldData.img_6.id = resData.img_6.id;

            setImages(oldData);

        } else {
            console.log(responce.data);
        }

        setLoader(false);
    }, []);


    useEffect(() => {
        if (location.state !== null) {
            getUserAdditionalImages();
        } else {
            navigate(-1)
        }
    }, [])

    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="add-photos_container">
            <div className="add-photos">
                <div className="row  justify-content-center">
                    {/* <div className="col-md-12 text-center">
                        <button className="button btn-sm btn-theme full-rounded" onClick={()=>navigate(-1)}>
                            Back
                        </button>
                    </div> */}
                    <div className="col-md-8">
                        <div className="form-group mb-3">
                            <div className="row">

                                <div className="col-md-8">

                                    <div className="ad-p-container">

                                        <img
                                            className="img-center w-100"
                                            src={Images.img_1.url}
                                            alt=""
                                        />{" "}

                                    </div>

                                </div>

                                <div className="col-md-4">

                                    <div className="row">
                                        <div className="col-md-12 mb-3 sm-mb-2">
                                            <div className="ad-p-container">

                                                <img
                                                    className="img-center"
                                                    src={Images.img_2.url}
                                                    alt=""
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12 mt-2">
                                            <div className="ad-p-container">
                                                {" "}
                                                <img
                                                    className="img-center"
                                                    src={Images.img_3.url}
                                                    alt=""
                                                />{" "}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="ad-p-container">
                                        {" "}
                                        <img
                                            className="img-center"
                                            src={Images.img_4.url}
                                            alt=""
                                        />{" "}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="ad-p-container">
                                        {" "}
                                        <img
                                            className="img-center"
                                            src={Images.img_5.url}
                                            alt=""
                                        />{" "}

                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="ad-p-container">
                                        {" "}
                                        <img
                                            className="img-center"
                                            src={Images.img_6.url}
                                            alt=""
                                        />{" "}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default memo(Index);