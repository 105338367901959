// import create_profile from "/images/timeline/create-profile.png";
// import find_match from '/images/timeline/find-match.png';
// import start_datting from "/images/timeline/start-datting.png";
// import founded from "/images/about/founded.jpg";
// import team_new_one from "/images/team/team-new-one.png";
// import team_new_two from '/images/team/team-new-two.png';
// import team_new_three from "/images/team/team-new-three.png";
// import team_new_four from "/images/team/team-new-four.png";
// import png_01 from "/images/counter/01.png";
// import png_02 from "/images/counter/02.png"
// import png_03 from "/images/counter/03.png"
// import png_04 from "/images/counter/04.png"
// import jpg_05 from "/images/blog/05.jpg";
// import jpg_01 from "/images/blog/01.jpg";
// import jpg_02 from "/images/blog/02.jpg";
// import thum_1 from "/images/thumbnail/thum-1.jpg";
// import thum_2 from "/images/thumbnail/thum-2.jpg";
// import thum_3 from "/images/thumbnail/thum-3.jpg";
// import play_icon from "/images/play-icon.png";

import OwlCarousel from "react-owl-carousel";
import Header from "./Header";
import Footer from "./Footer";
import { memo, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";


function Index() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isAuthenticated } = useSelector((state) => state.auth);

    const [StaticContent, setStaticContent] = useState({
        about_us: "",
        privacy_policy: "",
        product_services: "",
        return_refund_policy: "",
        terms_condition: "",
        NEIGHBORING_WORLD_IN_REAL_TIME: "",
        Why_Real_Time_Village: "",
        disclaimer: "",
        Step_To_Find_Your_Connection_text: "",
        Step_To_Find_Your_Connection_step_1: "",
        Step_To_Find_Your_Connection_step_1_content: "",
        Step_To_Find_Your_Connection_step_2: "",
        Step_To_Find_Your_Connection_step_2_content: "",
        Step_To_Find_Your_Connection_step_3: "",
        Step_To_Find_Your_Connection_step_3_content: ""
    });

    const getAllStaticContent = async () => {
        const responce = await new ApiService().GET("/get-all-static-content");

        if (responce.data.status === '1') {
            setStaticContent(responce.data.data);
        }
    }

    useEffect(() => {
        getAllStaticContent();
    }, [])

    return (
        <>
            <Header />

            {/*================================= banner ========= */}
            <section id="home-slider" className="fullscreen">
                <div
                    className="banner bg-1 w-100 d-flex bg-overlay-red align-items-center"
                    style={{ backgroundImage: "url(images/about/new_about.jpg)" }}
                >
                    <div className="container">
                        <div className="row text-center position-relative">
                            <div className="col-md-12 text-white">
                                <div className="text banner-main-text">
                                    <h1>
                                        {/* tired <span>of</span> being <span> alone</span> */}
                                        <span style={{ color: "#fff" }}>Find Your Match and Connect</span>
                                        <span id="changingword">Create Profile,</span>
                                    </h1>
                                </div>
                                
                            </div>
                            <div className="banner-store-wraper">
                                   <div>
                                   <a href="https://play.google.com/store/apps/details?id=com.realtimevillageandroid">
                                   <img
                                           
                                            src="/images/googlepay-btn.png"
                                            alt="store"
                                        />
                                        </a>
                                   </div>
                                   <div>
                                   <a href="https://play.google.com/store/apps/details?id=com.realtimevillageandroid&pcampaignid=web_share">
                                   <img
                                          
                                            src="/images/app-store.png"
                                            alt="store"
                                        />
                                        </a>
                                   </div>
                                </div>
                           
                        </div>
                    </div>
                </div>
            </section>
            {/*=================================  banner ========= */}
            {/*=================================  Page Section ======= */}
            <section className="page-section-ptb position-relative timeline-section">
                <div className="container">
                    <div className="row justify-content-center mb-4 mb-md-5">
                        <div className="col-md-10 text-center">
                            <h2 className="title divider mb-3">{parse(StaticContent.Step_To_Find_Your_Connection_text)}</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <ul className="timeline list-inline">
                                <li>
                                    <div className="timeline-badge">
                                        <img
                                            className="img-fluid"
                                            src="/images/timeline/create-profile.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading text-center">
                                            <h4 className="timeline-title divider-3">{parse(StaticContent.Step_To_Find_Your_Connection_step_1)}</h4>
                                        </div>
                                        <div className="timeline-body">
                                            {parse(StaticContent.Step_To_Find_Your_Connection_step_1_content)}
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-inverted">
                                    <div className="timeline-badge">
                                        <img
                                            className="img-fluid"
                                            src="/images/timeline/find-match.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading text-center">
                                            <h4 className="timeline-title divider-3">{parse(StaticContent.Step_To_Find_Your_Connection_step_2)}</h4>
                                        </div>
                                        <div className="timeline-body">
                                            {parse(StaticContent.Step_To_Find_Your_Connection_step_2_content)}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="timeline-badge">
                                        <img
                                            className="img-fluid"
                                            src="/images/timeline/start-datting.png"
                                            alt=""
                                        />
                                    </div>
                                    <div className="timeline-panel">
                                        <div className="timeline-heading text-center">
                                            <h4 className="timeline-title divider-3">{parse(StaticContent.Step_To_Find_Your_Connection_step_3)}</h4>
                                        </div>
                                        <div className="timeline-body">
                                            {parse(StaticContent.Step_To_Find_Your_Connection_step_3_content)}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/*================================= Page Section ======= */}
            <section className="page-section-ptb" id="about-us">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center mb-4 mb-md-5">
                            <h2 className="title  mb-3">
                                About Us{" "}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 align-self-center mb-3">
                            <img
                                className="img-fluid"
                                src="/images/about/new_about.jpg"
                                alt="founded"
                            />
                        </div>
                        <div className="col-md-6">
                            {parse(StaticContent.about_us)}
                        </div>
                    </div>
                </div>
            </section>



            {!isAuthenticated && <Footer />}
        </>

    )
}

export default memo(Index)