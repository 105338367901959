import { NavLink } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { useEffect, useState } from "react";
import PreLoader from "../../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegistrationSchema } from "../../../Schemas";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


import 'react-phone-number-input/style.css';
import ApiService from "../../../services/ApiService";


function Index() {

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);


    ///////////  Phone ///////////
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [PhoneValue, setPhoneValue] = useState("");

    // eslint-disable-next-line no-unused-vars, react-hooks/rules-of-hooks
    const [PhoneValueError, setPhoneValueError] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [PhoneSelectedCountry, setPhoneSelectedCountry] = useState("");
    ///////////  End Phone ///////////


    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [latitude, setLatitude] = useState(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [longitude, setLongitude] = useState(null);


    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow1, setPasswordShow1] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [PasswordShow2, setPasswordShow2] = useState(false);


    const {
        register,
        handleSubmit,
        reset,
        // setValue,
        // getValues,
        // trigger,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(RegistrationSchema),
        mode: 'all'
    });


    const getMyLocation = () => {

        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);

                    console.log(`latitude: ${position.coords.latitude} | longitude: ${position.coords.longitude}`);
                },
                (error) => {
                    console.log(`Error: ${error.message}`);
                }
            );
        } else {
            console.log('Geolocation is not supported by your browser');
        }

    }


    const submit = async (data) => {
        try {

            //////////  phone number validation /////////////
            // console.log(PhoneValue);
            // console.log(PhoneSelectedCountry);
            // console.log(isValidPhoneNumber(PhoneValue));

            if (PhoneValue === "" || PhoneValue === undefined) {
                setPhoneValueError("Phone number required");
                return;
            } else {
                if (isValidPhoneNumber(PhoneValue) === false) {
                    setPhoneValueError("Enter valid Phone number");
                    return;
                }
            }
            setPhoneValueError("");
            //////////  phone number validation /////////////

            setLoader(true);
            data.telephone = PhoneValue;
            data.telephone_code = PhoneSelectedCountry;
            data.latitude = latitude;
            data.longitude = longitude;


            // console.log(data)

            /////////////////// Api call ///////////////////////
            var responce = await new ApiService().POST("/registration", data);

            console.log(responce.data)

            if (responce.data.status) {
                toast.success(responce.data.msg);

                navigate("/otp", {
                    state: {
                        email: data.email,
                        page_show_permission: true,
                    }
                });

                reset("");
                setPhoneValue("");
                setPhoneSelectedCountry("");
            } else {
                toast.warning(responce.data.msg);
            }

            setLoader(false);


        } catch (err) {
            console.log(err);
            setLoader(false);
        }
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        getMyLocation();
    }, [])


    return (
        <>
            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}

            {/* <Header /> */}


            <>
                {/*================================= inner-intro*/}
                {/* <section
                    className="inner-intro bg bg-fixed bg-overlay-black-60"
                    style={{ backgroundImage: "url(images/bg/main-breadcrumb.jpg)" }}
                >
                    <div className="container">
                        <div className="row intro-title text-center">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h1 className="position-relative divider">
                                        register 1<span className="sub-title w-100">register 1</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-12 mt-7">
                                <ul className="page-breadcrumb">
                                    <li>
                                        <a href="index-default.html">
                                            <i className="fa fa-home" /> Home
                                        </a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <a href="register.html">Pages</a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <span>register 1</span>{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section> */}
                {/*================================= inner-intro*/}


                {/*================================= register*/}
                <section
                    className=" register-vh  login-form register-img dark-bg page-section-ptb"
                    style={{
                        background: "url(images/pattern/04.png) no-repeat 0 0",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container">
                        <div className="row  justify-content-center">
                            <div className="col-lg-6">
                                <form onSubmit={handleSubmit(submit)} >
                                    <div className="login-1-form register-1-form clearfix text-center">
                                        <h4 className="title divider-3 text-white mb-3">register</h4>
                                        <div className="section-field mb-3">
                                            <div className="field-widget">
                                                {" "}
                                                <i className="fa fa-envelope-o" aria-hidden="true" />
                                                <input
                                                    id="email"
                                                    className="email"
                                                    type="email"
                                                    placeholder="Email"
                                                    {...register("email")}
                                                />
                                                <p style={{ color: "red" }} className="form-field-error">
                                                    {errors.email?.message}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="section-field mb-3 custom-select-country">
                                            {/* <input
                                                type="number"
                                                id="mobile_code"
                                                className="form-control"
                                                placeholder="+ 91"
                                                {...register("telephone")}
                                            />
                                            <p style={{ color: "red" }} className="form-field-error">
                                                {errors.telephone?.message}
                                            </p>
                                            <ul>
                                                <li>
                                                    <img src="/images/flag-icon/india.png" alt="country" />
                                                </li>
                                                <li>
                                                    <img src="/images/flag-icon/usa.png" alt="country" />
                                                </li>
                                                <li>
                                                    <img src="/images/flag-icon/usa.png" alt="country" />
                                                </li>
                                            </ul> */}

                                            <PhoneInput

                                                placeholder="Enter phone number"
                                                value={PhoneValue}
                                                onChange={setPhoneValue}
                                                country={PhoneSelectedCountry}
                                                onCountryChange={setPhoneSelectedCountry} />

                                            <p style={{ color: "red" }} className="form-field-error">
                                                {PhoneValueError}
                                            </p>

                                        </div>
                                        <div className="section-field mb-3">
                                            <div className="field-widget">
                                                {" "}
                                                <i className="glyph-icon flaticon-padlock" />
                                                <input
                                                    id="Password"
                                                    className="Password"
                                                    type={PasswordShow1 ? "text" : "password"}
                                                    placeholder="Password"
                                                    {...register("pass")}
                                                />

                                                {PasswordShow1 ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow1(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow1(true)} />}

                                                <p style={{ color: "red" }} className="form-field-error">
                                                    {errors.pass?.message}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="section-field mb-3">
                                            <div className="field-widget">
                                                {" "}
                                                <i className="glyph-icon flaticon-padlock" />
                                                <input
                                                    id="Password"
                                                    className="Password"
                                                    type={PasswordShow2 ? "text" : "password"}
                                                    placeholder="Confirm Password"
                                                    {...register("conpass")}
                                                />

                                                {PasswordShow2 ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow2(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow2(true)} />}

                                                <p style={{ color: "red" }} className="form-field-error">
                                                    {errors.conpass?.message}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="section-field text-uppercase text-center mt-2">
                                            <button type="submit" className="button  btn-lg btn-theme full-rounded animated right-icn">
                                                <span>
                                                    next
                                                    <i
                                                        className="glyph-icon flaticon-hearts"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </button>
                                        </div>

                                        <div className="clearfix" />
                                        <div className="section-field mt-2 text-center text-white">
                                            <p className="lead mb-0">
                                                Have Already an Account! <br />
                                                <NavLink className="text-white" to={"/login"}>
                                                    <u>SIGN IN</u>{" "}
                                                </NavLink>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= register*/}
            </>


            {/* <Footer /> */}
        </>
    )
}

export default Index