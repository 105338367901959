import { NavLink } from "react-router-dom";
import PreLoader from "../../PreLoader";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchama } from "../../../Schemas";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ApiService from "../../../services/ApiService";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/auth";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, signInWithRedirect, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase_auth } from '../../../FirebaseSetup';

function Index() {

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [Loader, setLoader] = useState(false);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();


  const {
    register,
    handleSubmit,
    reset,
    // setValue,
    // getValues,
    // trigger,
    formState: { errors },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useForm({
    resolver: yupResolver(LoginSchama),
    mode: 'all'
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [latitude, setLatitude] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [longitude, setLongitude] = useState(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
  const [PasswordShow, setPasswordShow] = useState(false);


  /////////////////// Social Login ///////////////////////////////
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [user] = useAuthState(firebase_auth);

  const FbLogin = () => {
    const provider = new FacebookAuthProvider();
    // signInWithRedirect(firebase_auth, provider);
    signInWithPopup(firebase_auth, provider).catch((error) => {
      toast.error("Sorry! something went wrong")
      console.error(error)
    });
  }


  const GoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    // signInWithRedirect(firebase_auth, provider);
    signInWithPopup(firebase_auth, provider).catch((error) => {
      toast.error("Sorry! something went wrong")
      console.error(error)
    });
  }


  const AppleLogin = () => {
    const provider = new OAuthProvider('apple.com');
    // signInWithRedirect(firebase_auth, provider);
    signInWithPopup(firebase_auth, provider).catch((error) => {
      toast.error("Sorry! something went wrong")
      console.error(error)
    });
  }

  const apiCall = async (provider, data) => {
    try {

      provider = provider.split(".", 1)[0];
      // console.log("social login provider: ", provider);
      // console.log("social login data: ", data);
      // console.log("latitude latitude: ", latitude)

      // setLoader(true);

      var responce;
      var new_data = {
        email: data.email,
        appId: data.uid,
        latitude: latitude,
        longitude: longitude
      };

      if (provider === "facebook") {

        // console.log(data)
        /////////////////// Api call ///////////////////////
        responce = await new ApiService().POST("/fb-login-return", new_data);

      }
      else if (provider === "google") {

        /////////////////// Api call ///////////////////////
        responce = await new ApiService().POST("/google-login-return", new_data);

      }
      else if (provider === "apple") {

        /////////////////// Api call ///////////////////////
        responce = await new ApiService().POST("/apple-login-return", new_data);

      }


      console.log(responce.data)

      if (responce.data.status == "error") {
        console.error(responce.data);
      }
      else if (responce.data.status == "2") {
        toast.warning(responce.data.message);
      } else if (responce.data.status == "3") {
        toast.error(responce.data.message);
      } else if (responce.data.status == "1") {

        var resData = responce.data;

        dispatch(authActions.Login(resData));

        if (resData.profile_status == 0) {
          navigate("/identification", {
            state: {
              page_show_permission: true,
            }
          });
        }
        else if (resData.preference_status == 0) {

          dispatch(authActions.ChangeIdentificationStatus(true));

          navigate("/preferences", {
            state: {
              page_show_permission: true,
            }
          });

        }
        //////////////// Comment out because profile image is mandatory ////////////////
        // else if (resData.selfie_status == 0) {
        //   toast.warning("Please upload your profile image");
        //   navigate("/identification", {
        //     state: {
        //       page_show_permission: true,
        //     }
        //   });

        // }
        else {

          dispatch(authActions.ChangeIdentificationStatus(true));
          dispatch(authActions.ChangePreferenceStatus(true));

          navigate("/find-match");
        }

      }
      else{
        toast.error(responce.data.message);
      }


      // setLoader(false);

    } catch (err) {
      console.error(err);
      // setLoader(false);
    }
  }
  //////////////////// End Social Login /////////////////////////


  const getMyLocation = () => {

    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);

          console.log(`latitude: ${position.coords.latitude} | longitude: ${position.coords.longitude}`);
        },
        (error) => {
          console.log(`Error: ${error.message}`);
        }
      );
    } else {
      console.log('Geolocation is not supported by your browser');
    }

  }


  const submit = async (data) => {
    try {

      setLoader(true);

      // console.log(data)

      var new_data = {
        email: data.email,
        pass: data.pass,
        latitude: latitude,
        longitude: longitude
      };

      /////////////////// Api call ///////////////////////
      var responce = await new ApiService().POST("/login", new_data);

      // console.log("email & pass : login details ",responce.data)

      if (responce.data.status == "2") {
        toast.warning(responce.data.message);
      } else if (responce.data.status == "3") {

        ///////// Email Not Verify ////////////
        toast.success(responce.data.message);

        navigate("/otp", {
          state: {
            email: data.email,
            page_show_permission: true,
          }
        });

        reset();

      } else if (responce.data.status == "1") {

        var resData = responce.data;

        dispatch(authActions.Login(resData));

        if (resData.profile_status == 0) {
          navigate("/identification", {
            state: {
              page_show_permission: true,
            }
          });
        }
        else if (resData.preference_status == 0) {

          dispatch(authActions.ChangeIdentificationStatus(true));

          navigate("/preferences", {
            state: {
              page_show_permission: true,
            }
          });

        }
        //////////////// Comment out because profile image is mandatory ////////////////
        // else if (resData.selfie_status == 0) {
        //   toast.warning("Please upload your profile image");
        //   navigate("/identification", {
        //     state: {
        //       page_show_permission: true,
        //     }
        //   });

        // }
        else {

          dispatch(authActions.ChangeIdentificationStatus(true));
          dispatch(authActions.ChangePreferenceStatus(true));

          navigate("/find-match");
        }

        reset();
      }

      setLoader(false);


    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }


  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {

    ////////////// fetch login user from firebase after social login ////////////////
    if (user !== null) {
      // ============ method 1 currently not use =================
      // var nextPageToken=1;
      // firebase_auth.listUsers(1000, nextPageToken).then((userRecords) => {
      //     userRecords.forEach((user) => {
      //         console.log("user:: "+user)
      //         console.log('User ID:', user.uid);
      //         console.log('Email:', user.email);
      //         // You can access other user properties as well
      //     });
      // }).catch((error) => {
      //         console.error('Error listing users:', error);
      //     });

      // ============ method 2 currently use =================
      user.providerData.forEach(async (profile) => {
        setLoader(true);
        console.log("profile: ", profile)
        // console.log("Sign-in provider: " + profile.providerId);
        // console.log("  Provider-specific UID: " + profile.uid);
        // console.log("  Name: " + profile.displayName);
        // console.log("  Email: " + profile.email);
        // console.log("  Photo URL: " + profile.photoURL);
        await apiCall(profile.providerId, profile);
        setLoader(false);
      });


    }
    ////////////// fetch login user from firebase after social login ////////////////


    getMyLocation();
  }, [user]);

  return (
    <>

      {/* ==== Loader ==== */}
      <PreLoader Loader={Loader} />
      {/* ==== Loader ==== */}


      {/* <Header /> */}

      {/*================================= inner-intro*/}
      {/* <section
        className="inner-intro bg bg-fixed bg-overlay-black-60"
        style={{ backgroundImage: "url(images/bg/main-breadcrumb.jpg)" }}
      >
        <div className="container">
          <div className="row intro-title text-center">
            <div className="col-md-12">
              <div className="section-title">
                <h1 className="position-relative divider">
                  login <span className="sub-title">login</span>
                </h1>
              </div>
            </div>
            <div className="col-md-12 mt-7">
              <ul className="page-breadcrumb">
                <li>
                  <a href="index-default.html">
                    <i className="fa fa-home" /> Home
                  </a>{" "}
                  <i className="fa fa-angle-double-right" />
                </li>
                <li>
                  <span>Login</span>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      {/*================================= inner-intro*/}

      {/*================================= login*/}
      <section
        className="login-form login-form-vh login-img dark-bg page-section-ptb "
        style={{
          background: "url(images/pattern/04.png) no-repeat 0 0",
          backgroundSize: "cover"
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="login-1-form clearfix text-center">
                <h4 className="title divider-3 text-white">SIGN IN</h4>
                <div className="login-1-social my-4 my-md-5 text-center clearfix">
                  <ul className="list-inline text-capitalize">
                    <li>
                      {/*  /////////////////////// Facebook Login /////////////////// */}
                      <button className="fb" onClick={FbLogin}>
                        <i className="fa fa-facebook" /> Facebook
                      </button>

                    </li>
                    <li>
                      {/* ///////////////////////////// Google Login ///////////////// */}
                      <button className="gplus" onClick={GoogleLogin}>
                        <i className="fa fa-google-plus" /> google+
                      </button>
                    </li>
                    <li>
                      {/* ///////////////////////////// Apple Login ///////////////// */}
                      <button className="apple" onClick={AppleLogin}>
                        <i className="fa fa-apple" /> apple
                      </button>
                    </li>
                  </ul>
                </div>
                <form onSubmit={handleSubmit(submit)} >
                  <div className="section-field mb-3">
                    <div className="field-widget">
                      {" "}
                      <i className="glyph-icon flaticon-user" />
                      <input
                        id="name"
                        className="web"
                        type="email"
                        placeholder="Email Id"
                        {...register("email")}
                      />
                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.email?.message}
                      </p>
                    </div>
                  </div>
                  <div className="section-field mb-3">
                    <div className="field-widget">
                      {" "}
                      <i className="glyph-icon flaticon-padlock" />
                      <input
                        id="Password"
                        className="Password"
                        type={PasswordShow ? "text" : "password"}
                        placeholder="Password"
                        {...register("pass")}
                      />

                      {PasswordShow ? <i className="show-hide-pass fa fa-eye" onClick={() => setPasswordShow(false)} /> : <i className="show-hide-pass fa fa-eye-slash" onClick={() => setPasswordShow(true)} />}

                      <p style={{ color: "red" }} className="form-field-error">
                        {errors.pass?.message}
                      </p>
                    </div>
                  </div>

                  <div className="section-field text-uppercase">
                    {" "}
                    <NavLink to={"/forgot-password"} className="float-end text-white">
                      Forgot Password?
                    </NavLink>{" "}
                  </div>
                  <div className="clearfix" />
                  <div className="section-field text-uppercase text-center mt-2">
                    {" "}
                    <button type="submit"
                      className="button  btn-lg btn-theme full-rounded animated right-icn"
                    >
                      <span>
                        sign in
                        <i
                          className="glyph-icon flaticon-hearts"
                          aria-hidden="true"
                        />
                      </span>
                    </button>{" "}
                  </div>
                </form>
                <div className="clearfix" />
                <div className="section-field mt-2 text-center text-white">
                  <p className="lead mb-0">
                    Don’t Have an Account? <br />
                    <NavLink className="text-white" to={"/register"}>
                      <u>REGISTER NOW</u>{" "}
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*================================= login*/}

      {/* <Footer /> */}

    </>
  )
}

export default Index