import React, { useEffect, useState } from 'react'
import ApiService from "../../services/ApiService";
import parse from 'html-react-parser';

function Index() {
    const [StaticContent, setStaticContent] = useState({
        about_us: "",
        privacy_policy: "",
        product_services: "",
        return_refund_policy: "",
        terms_condition: "",
        NEIGHBORING_WORLD_IN_REAL_TIME: "",
        Why_Real_Time_Village: "",
        disclaimer: "",
        Step_To_Find_Your_Connection_text: "",
        Step_To_Find_Your_Connection_step_1: "",
        Step_To_Find_Your_Connection_step_1_content: "",
        Step_To_Find_Your_Connection_step_2: "",
        Step_To_Find_Your_Connection_step_2_content: "",
        Step_To_Find_Your_Connection_step_3: "",
        Step_To_Find_Your_Connection_step_3_content: ""
    });


    const getAllStaticContent = async () => {
        const responce = await new ApiService().GET("/get-all-static-content");

        if (responce.data.status === '1') {
            setStaticContent(responce.data.data);
        }
    }

    useEffect(() => {
        getAllStaticContent();
    }, [])


    return (<>
        {/*================================= Page Section ======= */}
        <section className="page-section-ptb" id="about-us">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center mb-4 mb-md-5">
                        <h2 className="title  mb-3">
                            About Us{" "}
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 align-self-center mb-3">
                        <img
                            className="img-fluid"
                            src="/images/about/new_about.jpg"
                            alt="founded"
                        />
                    </div>
                    <div className="col-md-6">
                        {parse(StaticContent.about_us)}
                    </div>
                </div>
            </div>
        </section>
    </>)
}

export default Index