// import Footer from "../../Footer"
// import Header from "../../Header"

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { toast } from "react-toastify";
import ApiService from "../../../services/ApiService";
import PreLoader from "../../PreLoader";


function index() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Email, setEmail] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [Code, setCode] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);


    /////////// Timer State ////////////
    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [seconds, setSeconds] = useState(120);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [ResendCode, setResendCode] = useState(false);
    /////////// Timer State ////////////


    const submit = async () => {
        try {

            // alert(Email);
            // alert(Code);

            if (Code.length < 4) {
                toast.warning("Please enter OTP");
                return;
            }

            setLoader(true);

            var data = {
                email: Email,
                code: Code
            };

            //////////////// Api call //////////////
            var responce = await new ApiService().POST("/account-active", data);

            // console.log(responce.data);

            if (responce.data.status) {
                toast.success(responce.data.msg);

                navigate("/login");
            } else {
                toast.error(responce.data.msg);
            }

            setLoader(false);
        }
        catch (err) {
            console.error(err);
            setLoader(false);
        }
    }


    const resendCodeSubmit = async () => {
        // Handle button click logic here
        // console.log('Button clicked!');

        setSeconds(120);
        setResendCode(false);
        
        
        var data = {
            email: Email,
        };

        //////////////// Api call //////////////
        var responce = await new ApiService().POST("/verification-code-resend", data);

        if (responce.data.status) {
            toast.success(responce.data.msg);
        } else {
            toast.error(responce.data.msg);
        }


    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        let timer;

        ///////////// page_show_permission Check ///////////////
        if (location.state === null) {
            navigate(-1)
        } else {

            if (location.state.page_show_permission) {

    
                setEmail(location.state.email);


                ///////////// resend timer ////////////


                if (seconds > 0 && !ResendCode) {
                    timer = setInterval(() => {
                        setSeconds(prevSeconds => prevSeconds - 1);
                    }, 1000);
                } else if (seconds === 0) {
                    setResendCode(true);
                }



            } else {
                navigate(-1)
            }
        }
        ///////////// page_show_permission Check ///////////////



        // Clean up the timer on component unmount
        return () => clearInterval(timer);


    }, [seconds])


    return (
        <>

            {/* ==== Loader ==== */}
            <PreLoader Loader={Loader} />
            {/* ==== Loader ==== */}
            {/* <Header /> */}

            <>
                {/*================================= banner */}
                <section
                    className="inner-intro bg bg-fixed bg-overlay-black-60"
                    style={{ backgroundImage: "url(images/bg/inner-bg-1.jpg)" }}
                >
                    <div className="container">
                        <div className="row intro-title text-center">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h1 className="position-relative divider">
                                        Verification Code
                                        <span className="sub-title">Verification Code</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-12 mt-7">
                                <ul className="page-breadcrumb">
                                    <li>
                                        <a href="index-default.html">
                                            <i className="fa fa-home" /> Home
                                        </a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <a href="contact-1.html">contact</a>{" "}
                                        <i className="fa fa-angle-double-right" />
                                    </li>
                                    <li>
                                        <span>Verification Code</span>{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= banner */}
                {/*================================= Page Section */}
                <section
                    className="login-form login-img dark-bg page-section-ptb otp-generator "
                    style={{
                        background: "url(images/pattern/04.png) no-repeat 0 0",
                        backgroundSize: "cover"
                    }}
                >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4">
                                <div className="login-1-form clearfix text-center">
                                    <h4 className="title divider-3 text-white">
                                        Enter Verification Code{" "}
                                    </h4>
                                    <p className="des">
                                        We have sent the code for verification to your{" "}
                                        <span>Mobile </span> Number and your
                                        <span>Email</span>{" "}
                                    </p>

                                    <div className="section-field mb-3 my-md-5">

                                        <div className="otp-generator-wrap">
                                            {/* <input
                                                type="text"
                                                className="otp-input"
                                                pattern="\d"
                                                maxLength={1}
                                            />
                                            <input
                                                type="text"
                                                className="otp-input"
                                                pattern="\d"
                                                maxLength={1}
                                            />
                                            <input
                                                type="text"
                                                className="otp-input"
                                                pattern="\d"
                                                maxLength={1}
                                            />
                                            <input
                                                type="text"
                                                className="otp-input"
                                                pattern="\d"
                                                maxLength={1}
                                            /> */}

                                            <VerificationInput
                                                classNames={"otp-input"}
                                                placeholder={""}
                                                length={4}
                                                validChars={"0-9"}
                                                autoFocus={true}
                                                value={Code}
                                                onChange={setCode} />


                                        </div>


                                        {ResendCode === true && <>
                                            <button type="button" onClick={resendCodeSubmit} className="resend">
                                                <u>resend</u>{" "}
                                            </button>
                                        </>}


                                        {ResendCode === false && <>

                                            <button className="resend">
                                                Resend in {`(${seconds}s)`}
                                            </button>

                                        </>}


                                    </div>

                                    <p className="des" style={{ fontSize: "12px" }}>
                                        Please check your spam in case the code does not come through your inbox! Thank you!
                                    </p>

                                    <div className="clearfix" />
                                    <div className="section-field text-uppercase text-center mt-2">

                                        <button type="button" onClick={submit} className="button  btn-lg btn-theme full-rounded animated right-icn">
                                            <span>
                                                Verify
                                                <i className="glyph-icon flaticon-hearts" aria-hidden="true" />
                                            </span>
                                        </button>


                                    </div>
                                    <div className="clearfix" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*================================= page-section */}
            </>


            {/* <Footer /> */}
        </>
    )
}

export default index