import React, { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import ApiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import PreLoader from "../PreLoader";
import Chat from "./Chat";

import { useLocation } from "react-router-dom";

function Index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);


    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    const [LoadChat, setLoadChat] = useState(false);

    const location = useLocation();

    const chkSelectChatPlan = async () => {
        setLoader(true);
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
        console.log("check-user-chat-plan-status ", response.data);

        if (response.data.status !== '1') {
            toast.warning("Please Buy Chat Plan");
            setLoader(false);
            navigate('/profile',{state:{tab_name:'packages'}})
        } else {
            setLoader(false);
            setLoadChat(true)
        }

    }

    useEffect(() => {
        chkSelectChatPlan();
    }, [])


    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        {LoadChat && <Chat location={location} />}
    </>
    );
};

export default memo(Index);
