import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function PaymentStatus() {

    const location = useLocation();

    console.log(location)

    return (<>
        <div className="payment-status-center">
            <div className='payment-status'>
                {location.state.status === "succeeded" ? (<>
                    <div className='payment-success'>
                        <img src="/images/icons/pay-success.png" alt="card" />
                        <h2>Payment Successfully</h2>
                    </div>
                </>) : (
                    <div className='payment-failed'>
                        <img src="/images/icons/pay-failed.png" alt="card" />
                        <h2>Payment Failed</h2>
                    </div>
                )}


                <br />

                <Link to={"/profile"}>
                    Continue
                </Link>
            </div>
        </div>
    </>)
}

export default PaymentStatus