import React, { memo, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Country } from "country-state-city";
import { Link, NavLink } from "react-router-dom"
// import {
//     GoogleMap,
//     MarkerF,
//     InfoWindow,
//     LoadScript,
// } from '@react-google-maps/api';

import { GoogleMap, MarkerF, InfoWindow, useJsApiLoader } from '@react-google-maps/api';

import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { push, ref, serverTimestamp, set } from "firebase/database";
import { firebase_db } from '../../FirebaseSetup';
import { newRoomIdGet } from '../../CommonFunc';
import PreLoader from '../PreLoader';
import moment from 'moment';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ThreeDots } from 'react-loader-spinner';
import { useQuery } from '@tanstack/react-query';


const mapStyles = {
    height: '100%',
    width: '100%',
};



function Index() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [map, setMap] = useState(null)


    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [SearchModal, setSearchModal] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [SearchUserKeyWord, setSearchUserKeyWord] = useState("");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [SearchUser, setSearchUser] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [VisibilityModal, setVisibilityModal] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [FilterModal, setFilterModal] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countries, setCountries] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [mapZoom, setmapZoom] = useState(7);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [Filter, setFilter] = useState({
        find_mode: 'automatic', // 'automatic','manual'
        visibility_mode: 'global', // 'local','global'
        age: "",
        marital: "",
        occupation: "",
        bodyType: ""
    });

    const ageOptions = [];
    for (let i = 18; i <= 100; i++) {
        ageOptions.push(i);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onLoad = useCallback(function callback(map) {


        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds({
        //     lat: Number(userData.latitude),
        //     lng: Number(userData.longitude),
        // });
        // map.fitBounds(bounds);

        const bounds = new window.google.maps.LatLngBounds({
            lat: Number(userData.latitude),
            lng: Number(userData.longitude),
        });

        map.fitBounds(bounds);

        //  alert(map.getZoom())
        // map.setZoom(10);

        setMap(map)
    }, [userData.latitude, userData.longitude])



    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [selectedMarker, setSelectedMarker] = useState(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [MatchUserLocation, setMatchUserLocation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [AutoMatchUserLocation, setAutoMatchUserLocation] = useState([]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [AgeError, setAgeError] = useState("");

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [CallChargeData, setCallChargeData] = useState({});

    const [AutomaticModalOpen, setAutomaticModalOpen] = useState(false);

    const [FilterLoader, setFilterLoader] = useState(false);

    const [FilterReset, setFilterReset] = useState(false);

    const [SelectedCountry, setSelectedCountry] = useState(null);


    const ChooseCountry = (data) => {
        setSelectedCountry(data);

        // console.log(data)

        setFilter((pre) => ({
            ...pre,
            ["visibility_mode"]: data.value
        }));
    }



    const handleMarkerClick = (marker) => {
        setmapZoom(5);
        console.log("select marker ", marker)
        const { miles, feet } = convertKilometersToMilesAndFeet(marker.distance);
        marker.miles = miles;
        marker.feet = feet;
        setSelectedMarker(marker);
    };

    const handleCloseInfoWindow = () => {
        setSelectedMarker(null);
        setmapZoom(7);
    };


    const matchPreferenceFilter = (key_name, key_value) => {
        // alert(key_value);

        setFilter((pre) => ({
            ...pre,
            [key_name]: key_value
        }));
    }

    const matchPreferenceFilterReset = () => {
        // alert(key_value);
        setSelectedCountry(null);

        setFilter((pre) => ({
            find_mode: 'manual',
            visibility_mode: 'global',
            age: "",
            marital: "",
            occupation: "",
            bodyType: ""
        }));

        setFilterReset(true);

    }

    const CallChargesDataGet = async () => {

        /////////////////// Api call ///////////////////////
        var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
        console.log("get-all-fees ", callFeesResponce.data);


        if (callFeesResponce.data.status === "1") {
            setCallChargeData(callFeesResponce.data.data)
        } else {
            setCallChargeData({});
        }
    }


    const matchPreferenceList = useCallback(async () => {

        setFilterLoader(true);

        console.log("Filter ", Filter);

        var filterData = Filter;
        filterData.userid = userData.id;

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/match-preference-list", filterData);

        if (responce.data.status === '1') {
            console.log("matchPreferenceList: ", responce.data)

            if (Filter.find_mode === "automatic") {
                setAutoMatchUserLocation(responce.data.userdetails);
                setMatchUserLocation(responce.data.userdetails);
                setMatchUserLocation([])
            } else {
                setMatchUserLocation(responce.data.userdetails);
                setAutoMatchUserLocation([]);
            }
        }
        else if (responce.data.status === '2') {
            // toast.warning(responce.data.message);
            setMatchUserLocation([]);
            setAutoMatchUserLocation([]);
        }
        else {
            console.error(responce.data);
        }


        if (Filter.find_mode === "automatic") {
            setAutomaticModalOpen(true);
        } else {
            setAutomaticModalOpen(false);
        }

        setFilterLoader(false);
        setFilterReset(false);
        setVisibilityModal(false);
        setFilterModal(false);

    }, [Filter, userData.id, AutomaticModalOpen, FilterLoader, VisibilityModal, FilterModal, AutoMatchUserLocation]);


    const AutomatiocModalClose = useCallback(async (find_mode) => {

        setFilterLoader(true);

        console.log("Filter ", Filter);

        var filterData = Filter;
        filterData.userid = userData.id;
        filterData.find_mode = find_mode;

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/match-preference-list", filterData);

        if (responce.data.status === '1') {
            console.log("matchPreferenceList: ", responce.data)

            if (Filter.find_mode === "automatic") {
                setAutoMatchUserLocation(responce.data.userdetails);
                setMatchUserLocation([])
            } else {
                setMatchUserLocation(responce.data.userdetails);
                setAutoMatchUserLocation([]);
            }
        }
        else if (responce.data.status === '2') {
            // toast.warning(responce.data.message);
            setMatchUserLocation([]);
            setAutoMatchUserLocation([]);
        }
        else {
            console.error(responce.data);
        }


        if (Filter.find_mode === "automatic") {
            setAutomaticModalOpen(true);
        } else {
            setAutomaticModalOpen(false);
        }

        setFilterLoader(false);
        setFilterReset(false);
        setVisibilityModal(false);
        setFilterModal(false);

    }, [Filter, userData.id, AutomaticModalOpen, FilterLoader, VisibilityModal, FilterModal, AutoMatchUserLocation]);



    const searchProfile = async (data) => {

        data = data.trim();
        setSearchUserKeyWord(data);

        console.log("setSearchUserKeyWord ", data);

        if (data === "") {
            setSearchUser([]);
            return;
        }


        var filterData = {
            userid: userData.id,
            search: data,
        }


        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/search-profile", filterData);

        if (responce.data.status === '1') {
            console.log("search-profile: ", responce.data)

            setSearchUser(responce.data.userdetails);
        }
        else if (responce.data.status === '2') {
            // toast.warning(responce.data.message);
            setSearchUser([]);
        }
        else {
            console.error(responce.data);
            setSearchUser([]);
        }


    };



    const convertKilometersToMilesAndFeet = (kilometers) => {
        const miles = kilometers * 0.621371; // 1 kilometer is approximately 0.621371 miles
        const feet = miles * 5280; // 1 mile is 5280 feet

        const milesPart = Math.floor(miles);
        const feetPart = Math.round(feet % 5280);

        return {
            miles: milesPart,
            feet: feetPart,
        };
    };


    // const EnterChatORrequestSend = async () => {

    //     ////////////////// Check chat plan have /////////////
    //     /////////////////// Api call ///////////////////////
    //     var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
    //     console.log("check-user-chat-plan-status ", response.data);

    //     if (response.data.status !== '1') {
    //         toast.warning("Please Buy Chat Plan");
    //         navigate("/profile");
    //         return;
    //     }

    //     /////////////////// Api call ///////////////////////
    //     var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&receiver_id=${selectedMarker.id}`);

    //     if (responce.data.status === '1') {
    //         navigate("/chat", {
    //             state: {
    //                 receiver_id: selectedMarker.id,
    //             }
    //         });
    //     } else {

    //         //  ===== Get a key for a new Post. ===== //
    //         // const newPostKey = push(ref(firebase_db)).key;
    //         // console.log(newPostKey)

    //         var roomId = await newRoomIdGet();

    //         set(ref(firebase_db, `/chatrooms/${roomId}`), {
    //             key: roomId,
    //             sender_id: userData.id,
    //             receiver_id: selectedMarker.id,
    //             created_at: serverTimestamp(),
    //             status: "pending", // 'pending', 'accept', 'reject'
    //             messages: []
    //         });

    //         let new_data = {
    //             room_id: roomId,
    //             sender_id: userData.id,
    //             receiver_id: selectedMarker.id,
    //             status: "pending"
    //         }

    //         /////////////////// Api call ///////////////////////
    //         var responce = await new ApiService().POST(`/add-chat-request`, new_data);

    //         if (responce.data.status === '1') {
    //             navigate("/chat", {
    //                 state: {
    //                     receiver_id: selectedMarker.id,
    //                 }
    //             });
    //         }

    //     }
    // }


    const fetchCountries = () => {
        const countryList = Country.getAllCountries();
        // console.log(JSON.stringify(countryList));
        // setCountries(countryList);

        const options = countryList.map(item => ({ value: item.isoCode, label: item.name }));

        setCountries(options);
    };



    ///////////// chat,audio and video call ////////////////////////
    const CheckCallSendOrNot = async (call_type) => {

        /////////////////// Api call ///////////////////////
        var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
        console.log("get-all-fees ", callFeesResponce.data);

        let fees = 0;

        if (call_type === "audiocall") {
            fees = Number(callFeesResponce.data.data.audiocall.charges);
        }
        else if (call_type === "videocall") {
            fees = Number(callFeesResponce.data.data.videocall.charges);
        }

        let ratePerMinute = fees;

        let sender_amount = Number(userData.wallet_amount);
        let receiver_amount = Number(selectedMarker.wallet_amount);

        ///// Calculate the sender total minutes ////
        let senderTotalMinutes = Math.round(sender_amount / ratePerMinute);

        ///// Calculate the receiver total minutes ////
        let receiverTotalMinutes = Math.round(receiver_amount / ratePerMinute);

        ////// sender check have minium 5 minutes balance ////
        if (senderTotalMinutes < 5) {
            toast.warning("You don't have sufficient balance to call")
            return false;
        }
        ////// receiver check have minium 1 minutes balance ////
        else if (receiverTotalMinutes < 1) {
            toast.warning("This user unable to take your call")
            return false;
        }

        return true;
    }

    const CalculateTotalCallMinutes = async (call_type) => {

        /////////////////// Api call ///////////////////////
        var callFeesResponce = await new ApiService().GET(`/get-all-fees`);
        console.log("get-all-fees ", callFeesResponce.data);

        let fees = 0;

        if (call_type === "audiocall") {
            fees = Number(callFeesResponce.data.data.audiocall.charges);
        }
        else if (call_type === "videocall") {
            fees = Number(callFeesResponce.data.data.videocall.charges);
        }

        let sender_amount = Number(userData.wallet_amount);
        let receiver_amount = Number(selectedMarker.wallet_amount);
        let ratePerMinute = fees;

        var amount = 0;

        ///// compare which amount small or equal ///
        if (sender_amount === receiver_amount) {
            amount = sender_amount;
        } else {

            if (sender_amount < receiver_amount) {
                amount = sender_amount;
            }
            else if (receiver_amount < sender_amount) {
                amount = receiver_amount;
            }

        }

        ///// Calculate the total minutes ////
        const totalSeconds = ((Math.round(amount / ratePerMinute)) * 60);

        // console.log("totalSeconds ", totalSeconds)

        return {
            sender_amount,
            receiver_amount,
            totalSeconds,
            ratePerMinute,
        }

    }

    const EnterChatORrequestSend = async () => {

        ////////////////// Check chat plan have /////////////
        /////////////////// Api call ///////////////////////
        var response = await new ApiService().GET(`/check-user-chat-plan-status/${userData.id}`);
        console.log("check-user-chat-plan-status ", response.data);

        if (response.data.status !== '1') {
            toast.warning("Please Buy Chat Plan");
            navigate("/profile");
            return;
        }

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().GET(`/get-chat-request?sender_id=${userData.id}&receiver_id=${selectedMarker.id}`);

        if (responce.data.status === '1') {
            navigate("/chat", {
                state: {
                    receiver_id: selectedMarker.id,
                }
            });
        } else {

            //  ===== Get a key for a new Post. ===== //
            // const newPostKey = push(ref(firebase_db)).key;
            // console.log(newPostKey)

            var roomId = await newRoomIdGet();

            set(ref(firebase_db, `/chatrooms/${roomId}`), {
                key: roomId,
                sender_id: userData.id,
                receiver_id: selectedMarker.id,
                created_at: serverTimestamp(),
                status: "pending", // 'pending', 'accept', 'reject'
                messages: []
            });

            let new_data = {
                room_id: roomId,
                sender_id: userData.id,
                receiver_id: selectedMarker.id,
                status: "pending"
            }

            /////////////////// Api call ///////////////////////
            var responce = await new ApiService().POST(`/add-chat-request`, new_data);

            if (responce.data.status === '1') {
                navigate("/chat", {
                    state: {
                        receiver_id: selectedMarker.id,
                    }
                });
            }

        }
    }

    const EnterAudioCallRrequestSend = () => {

        Swal.fire({
            title: `Audio Call charges $${CallChargeData?.audiocall?.charges?.toFixed(2)}/min`,
            showCancelButton: true,
            confirmButtonText: "Continue",
        }).then(async (result) => {
            if (result.isConfirmed) {

                setLoader(true);
                let new_data = {
                    userid: selectedMarker.id,
                }
                /////////////////// Api call ///////////////////////
                var userBusyResponce = await new ApiService().POST(`/get-pnotify-key`, new_data);
                console.log("userBusyResponce ", userBusyResponce.data)

                if (userBusyResponce.data.status === '1') {

                    if (userBusyResponce.data.loginstatus !== "1") {
                        toast.warning("User offline");
                        setLoader(false);
                        return;
                    }

                    let checkCallSendOrNot = await CheckCallSendOrNot("audiocall");
                    if (!checkCallSendOrNot) {
                        setLoader(false);
                        return;
                    }

                    //  ===== Get a key for a new Post. ===== //
                    // const newPostKey = push(ref(firebase_db)).key;
                    // console.log(newPostKey)
                    var roomId = await newRoomIdGet();


                    let new_data = {
                        genkey: roomId,
                        senderid: userData.id,
                        receiverid: selectedMarker.id,
                        starttime: moment().unix(),
                        endtime: moment().add(1.1, 'minutes').unix() /// this time will be dynamic with wallet amount
                    }

                    /////////////////// Api call ///////////////////////
                    var responce = await new ApiService().POST(`/send-request`, new_data);
                    console.log("send-request ", responce.data)

                    if (responce.data.status === '1') {

                        if (responce.data.block_count > 0) {
                            toast.warning("User blocked you!");
                            setLoader(false);
                            return;
                        }


                        //////////////// Calculate total call minutes /////////////
                        var callObj = await CalculateTotalCallMinutes("audiocall");
                        console.log("callObj", callObj);


                        set(ref(firebase_db, `/user_video_audio_call_requests/${roomId}`), {
                            key: roomId,
                            sender_id: userData.id,
                            receiver_id: selectedMarker.id,
                            created_at: serverTimestamp(),
                            call_type: 'audio',
                            notificationid: responce.data.notification_id,
                            status: "pending", // 'pending','accept','reject','complete'
                            rating_status: "pending",
                            totalSeconds: callObj.totalSeconds,
                            ratePerMinute: callObj.ratePerMinute,
                            sender_details: {
                                profile_pic_pro_resize_200_url: userData.profile_pic_pro_resize_200_url,
                                name: userData.name,
                                gender: userData.gender,
                                wallet_amount: userData.wallet_amount
                            },
                            receiver_details: {
                                profile_pic_pro_resize_200_url: selectedMarker.profile_pic_pro_resize_200_url,
                                name: selectedMarker.name,
                                gender: selectedMarker.gender,
                                wallet_amount: selectedMarker.wallet_amount
                            },
                        });


                        // navigate("/audio-call", {
                        //   state: {
                        //     page_access: true,
                        //     room_id: roomId,
                        //   }
                        // });
                    } else {
                        toast.warning(responce.data.message);
                    }



                } else {
                    toast.warning(userBusyResponce.data.message);
                }
                setLoader(false);


            }
        })


    }


    const EnterVideoCallRrequestSend = () => {

        Swal.fire({
            title: `Video Call charges $${CallChargeData?.videocall?.charges?.toFixed(2)}/min`,
            showCancelButton: true,
            confirmButtonText: "Continue",
        }).then(async (result) => {
            if (result.isConfirmed) {

                setLoader(true);

                let new_data = {
                    userid: selectedMarker.id,
                }
                /////////////////// Api call ///////////////////////
                var userBusyResponce = await new ApiService().POST(`/get-pnotify-key`, new_data);
                console.log("userBusyResponce ", userBusyResponce.data)

                if (userBusyResponce.data.status === '1') {

                    if (userBusyResponce.data.loginstatus !== "1") {
                        toast.warning("User offline");
                        setLoader(false);
                        return;
                    }

                    let checkCallSendOrNot = await CheckCallSendOrNot("videocall");
                    if (!checkCallSendOrNot) {
                        setLoader(false);
                        return;
                    }

                    //  ===== Get a key for a new Post. ===== //
                    // const newPostKey = push(ref(firebase_db)).key;
                    // console.log(newPostKey)
                    var roomId = await newRoomIdGet();


                    let new_data = {
                        genkey: roomId,
                        senderid: userData.id,
                        receiverid: selectedMarker.id,
                        starttime: moment().unix(),
                        endtime: moment().add(1.10, 'minutes').unix() /// this time will be dynamic with wallet amount
                    }

                    /////////////////// Api call ///////////////////////
                    var responce = await new ApiService().POST(`/send-request`, new_data);
                    console.log("send-request ", responce.data)

                    if (responce.data.status === '1') {

                        if (responce.data.block_count > 0) {
                            toast.warning("User blocked you!");
                            setLoader(false);
                            return;
                        }

                        //////////////// Calculate total call minutes /////////////
                        var callObj = await CalculateTotalCallMinutes("videocall");
                        console.log("callObj", callObj);

                        set(ref(firebase_db, `/user_video_audio_call_requests/${roomId}`), {
                            key: roomId,
                            sender_id: userData.id,
                            receiver_id: selectedMarker.id,
                            created_at: serverTimestamp(),
                            call_type: 'video',
                            notificationid: responce.data.notification_id,
                            status: "pending", // 'pending','accept','reject','complete'
                            rating_status: "pending",
                            totalSeconds: callObj.totalSeconds,
                            ratePerMinute: callObj.ratePerMinute,
                            sender_details: {
                                profile_pic_pro_resize_200_url: userData.profile_pic_pro_resize_200_url,
                                name: userData.name,
                                gender: userData.gender,
                                wallet_amount: userData.wallet_amount
                            },
                            receiver_details: {
                                profile_pic_pro_resize_200_url: selectedMarker.profile_pic_pro_resize_200_url,
                                name: selectedMarker.name,
                                gender: selectedMarker.gender,
                                wallet_amount: selectedMarker.wallet_amount
                            },
                        });


                        // navigate("/audio-call", {
                        //   state: {
                        //     page_access: true,
                        //     room_id: roomId,
                        //   }
                        // });
                    } else {
                        toast.warning(responce.data.message);
                    }



                } else {
                    toast.warning(userBusyResponce.data.message);
                }

                setLoader(false);

            }
        })


    }
    ///////////// chat,audio and video call ////////////////////////


    //// notification ////
    const {
        data: notificationData = {},
    } = useQuery({
        queryKey: ['notification'],
        queryFn: async () => {
            /////////////////// Api call ///////////////////////
            var response = await new ApiService().POST(`/new-user-info`, {
                userid: userData.id,
            });
            console.log("get-all-notifications ", response.data);
            return response.data;
        },
    });


    useEffect(() => {
        CallChargesDataGet();
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        matchPreferenceList();
    }, []);

    // useEffect(() => {
    //     if(Filter.find_mode === "automatic" && AutomaticModalOpen === false) {
    //         matchPreferenceList();
    //     }else if(Filter.find_mode === "manual" && AutomaticModalOpen === true) {
    //         setAutomaticModalOpen(false);
    //         matchPreferenceList();
    //     }

    // }, [Filter]);


    useEffect(() => {
        if (FilterReset) {
            matchPreferenceList();
        }
    }, [FilterReset]);







    return (<>

        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        {/*================================= inner-intro ============ */}
        <section
            className="inner-intro bg bg-fixed bg-overlay-black-60"
            style={{ backgroundImage: "url(/images/bg/main-breadcrumb.jpg)", display: 'none', }}
        >
            <div className="container">
                <div className="row intro-title text-center">
                    <div className="col-md-12">
                        <div className="section-title">
                            <h1 className="position-relative divider">
                                Find Match<span className="sub-title w-100">Find Match</span>
                            </h1>
                        </div>
                    </div>
                    <div className="col-md-12 mt-7">
                        <ul className="page-breadcrumb">
                            <li>
                                <NavLink to={"/"}>
                                    <i className="fa fa-home" /> Home
                                </NavLink>{" "}
                                <i className="fa fa-angle-double-right" />
                            </li>
                            <li>
                                <a href="#">Pages</a>{" "}
                                <i className="fa fa-angle-double-right" />
                            </li>
                            <li>
                                <span>Find Match</span>{" "}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        {/*================================= inner-intro ==============*/}

        {/*================================= login ============= */}
        <section className="find-partner">

            {/* wishlist-container-start==== */}
            {((AutomaticModalOpen === true)) && <div className="connection-container">

                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", marginBottom: "5px" }}>
                    <button type='button'
                        className='btn'
                        onClick={matchPreferenceFilterReset}
                        style={{
                            border: "none",
                            borderRadius: "50%",
                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"
                        }}
                    >X</button>
                </div>


                {AutoMatchUserLocation.length > 0 && <div className='top-alignment'>
                    <ul>
                        <li>Name</li>
                        <li>Match %</li>
                        <li>Distance</li>
                    </ul>
                </div>}

                {AutoMatchUserLocation.map((item, index) => (<>
                    <div key={"wishlist_" + index} className="connection" onClick={() => navigate('/view-profile', {
                        state: {
                            profile_id: item.id,
                            page_show_permission: true
                        }
                    })} style={{ cursor: "pointer" }}>
                        <img className="img-fluid" src={item.profile_pic_pro_resize_200_url} alt='' />
                        <div className="profile-info d-flex justify-content-between">
                            <div className="profile-name">{item.name}</div>
                            <div className="match-percentage">{item.matchpercent}</div>
                            <div className="profile-distance">{item.distancedata_2.miles} miles away</div>
                        </div>

                    </div>
                </>))}

                {AutoMatchUserLocation.length === 0 && <div>
                    <h5 className='text-center'>No data found. Please Adjust Your Filter.</h5>
                </div>}

                {/* <div class="connection">
                        <img className="img-fluid" src={'/images/profile/profile-img.png'} alt='' />
                        <div class="profile-info">
                            <div class="profile-name">John Doe</div>
                            <div class="profile-distance">3 miles away</div>
                        </div>
                        <div class="match-percentage">85% Match</div>
                    </div> */}

            </div>}
            {/* wishlist-container-end==== */}

            <div className="side-option">
                <ul>
                    <li>
                        <button
                            type='button'
                            className='btn'
                            onClick={() => {
                                matchPreferenceFilter('find_mode', 'manual');
                                setAutomaticModalOpen(false);
                                setSearchModal(true);
                            }}
                        >
                            <img className="img-fluid" src={'/images/team/globe-search.svg'} alt='' />
                        </button>
                        Search
                    </li>
                    {/* <li>
                        <button type='button' className='btn' onClick={() => setVisibilityModal(true)}>
                            <img className="img-fluid" src={'/images/team/globe-icon.svg'} alt='' /></button>
                        Visibility
                    </li> */}
                    <li>
                        <button
                            type='button'
                            className='btn'
                            onClick={() => {
                                matchPreferenceFilter('find_mode', 'manual');
                                setAutomaticModalOpen(false);
                                setFilterModal(true);
                                fetchCountries();
                            }}
                        >
                            <img className="img-fluid" src={'/images/team/filter-icon.svg'} alt="" />
                        </button>
                        Filters
                    </li>
                    <li className='notification_button'>
                        <button type='button' className='btn' onClick={() => navigate("/notification")}><img className="img-fluid" src={'/images/team/notification-icon.svg'} alt="" /></button>
                        Notifi...
                        {(notificationData?.notify_count > 0) && <span class="notification_button__badge">{notificationData?.notify_count}</span>}
                    </li>
                    <li>
                        <button type='button' className='btn' onClick={() => navigate('/profile', { state: { tab_name: 'funds' } })}><img className="img-fluid" src={'/images/team/Walet-icon.svg'} alt="" /></button>
                        ${Number(userData.wallet_amount).toFixed(2)}
                    </li>
                </ul>
            </div>



            <div className="map-container">

                {isLoaded && <GoogleMap
                    mapContainerStyle={mapStyles}

                    center={{
                        lat: Number(userData.latitude),
                        lng: Number(userData.longitude),
                    }}

                    zoom={mapZoom}

                    // onLoad={onLoad}
                    onUnmount={onUnmount}

                    onClick={handleCloseInfoWindow}
                >
                    {MatchUserLocation.map((item, index) => (
                        <div style={{ width: '30px', height: '30px', borderRadius: '50%', overflow: 'hidden' }}>
                            <MarkerF
                                class="ppppppp"
                                key={"map_marker_" + index}
                                draggable={false}
                                animation="DROP"
                                position={{
                                    lat: Number(item.latitude),
                                    lng: Number(item.longitude),
                                }}
                                // label={item.name}
                                icon={{
                                    url: item.profile_pic_map_icon_url,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(20, 20),
                                }}

                                style={{
                                    clipPath: 'circle(50% at 50% 50%)', // Adjust radius value as needed
                                }}

                                onClick={() => handleMarkerClick(item)}


                            />
                        </div>

                    ))}


                    {(selectedMarker !== null) && (
                        <InfoWindow

                            position={{
                                lat: Number(selectedMarker.latitude),
                                lng: Number(selectedMarker.longitude),
                            }}

                            onCloseClick={handleCloseInfoWindow}
                        >

                            <div className="pro-details-show-container">
                                <div className="pro-details-show">
                                    <div className="profile-overlay">
                                        <div>
                                            <img
                                                src={selectedMarker.profile_pic_map_profile_url}
                                                alt="profile"
                                            />
                                            <small className={`${selectedMarker.login_status === "1" ? "online" : ""}`}>{selectedMarker.login_status === "1" ? "Online" : "Offline"}</small>
                                        </div>
                                        <div className="content-wrap">
                                            <span>Name: {selectedMarker.name}</span>
                                            <br />
                                            <span>Gender: {selectedMarker.gender}</span>
                                            <br />
                                            <span>Distance: {selectedMarker.miles} miles away</span>
                                            <ul>
                                                <li>
                                                    <button type='button' onClick={EnterChatORrequestSend}>
                                                        <img src="/images/inner-page-img/sms.svg" alt="sms" />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type='button' onClick={EnterVideoCallRrequestSend}>
                                                        <img
                                                            src="/images/inner-page-img/videocall.svg"
                                                            alt="videocall"
                                                        />
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type='button' onClick={EnterAudioCallRrequestSend}>
                                                        <img src="/images/inner-page-img/call.svg" alt="call" />
                                                    </button>
                                                </li>
                                            </ul>
                                            <Link to={"/view-profile"}
                                                state={{
                                                    profile_id: selectedMarker.id,
                                                    page_show_permission: true
                                                }}
                                                className="view-profile">
                                                view-profile
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                    )}


                </GoogleMap>}




            </div>

        </section>



        {/* ///////////// Search Modal ///////////////// */}
        <Modal
            show={SearchModal}
            onHide={() => setSearchModal(false)}
            className="VisibilityModal search-modal"
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='v-m-content search-modal_content'>
                    <ul className='search-fil'>
                        <li>
                            <input type='text' placeholder='Search connection' value={SearchUserKeyWord} onChange={(e) => searchProfile(e.target.value)} />
                            <button type='button' onClick={() => searchProfile("")} ><i class="fa-solid fa-xmark"></i></button>
                        </li>

                    </ul>

                    {SearchUser.length > 0 && <div className='connection-container'>

                        {SearchUser.map((item, index) => (<>

                            <div key={"wishlist_" + index} class="connection" onClick={() => navigate('/view-profile', {
                                state: {
                                    profile_id: item.id,
                                    page_show_permission: true
                                }
                            })} style={{ cursor: "pointer" }}>
                                <img className="img-fluid" src={item.profile_pic_pro_resize_200_url} alt='' />
                                <div class="profile-info">
                                    <div class="profile-name">{item.name}</div>
                                    <div class="profile-distance">{item.distancedata_2.miles} miles away</div>
                                </div>
                                <div class="match-percentage">{item.matchpercent}% Match</div>
                            </div>

                        </>))}

                    </div>}

                </div>
            </Modal.Body>
        </Modal>


        {/* ///////////// Visibility Modal ///////////////// */}
        {/* <Modal
            show={VisibilityModal}
            onHide={() => setVisibilityModal(false)}
            className="VisibilityModal"
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Visibility</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='v-m-content'>
                    <ul>
                        <li>
                            <h6>Find Mode:</h6>
                            <div className='btn-wrap'>
                                <button
                                    type='button'
                                    className={`btn btn-sm ${Filter.find_mode === 'automatic' && 'btn-success'}`}
                                    onClick={() => matchPreferenceFilter('find_mode', 'automatic')} >Automatic</button>

                                <button
                                    type='button'
                                    className={`btn btn-sm ${Filter.find_mode === 'manual' && 'btn-success'}`}
                                    onClick={() => matchPreferenceFilter('find_mode', 'manual')} >Manual</button>
                            </div>
                        </li>
                        <li>
                            <h6>Visibility Mode:</h6>
                            <div className="btn-wrap">
                                <button
                                    type='button'
                                    className={`btn btn-sm ${Filter.visibility_mode !== 'local' && 'btn-success'}`}
                                    onClick={() => matchPreferenceFilter('visibility_mode', 'global')} >Global</button>

                                <button
                                    type='button'
                                    className={`btn btn-sm ${Filter.visibility_mode === 'local' && 'btn-success'}`}
                                    onClick={() => matchPreferenceFilter('visibility_mode', 'local')} >Local</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ThreeDots
                    visible={FilterLoader}
                    height="60"
                    width="60"
                    color="#8bbc46"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <button className="button  btn-sm btn-theme full-rounded" style={{ marginRight: "6px" }} onClick={matchPreferenceList}>
                    Submit
                </button>
                <button className="button  btn-sm btn-theme full-rounded" onClick={matchPreferenceFilterReset}>
                    Reset
                </button>
            </Modal.Footer>
        </Modal> */}



        {/* ///////////// Filter Modal ///////////////// */}
        <Modal
            show={FilterModal}
            onHide={() => setFilterModal(false)}
            className="VisibilityModal filter-modal"
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <section
                        className="page-section-ptb profile-setup"
                        style={{
                            background: "url(/images/pattern/04.png) no-repeat 0 0",
                            backgroundSize: "cover"
                        }}
                    >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6">
                                    <form>
                                        <div className="select-gender">
                                            <div className="sel-radio">

                                                <span className="sel-heading mt-4">Visibility Mode</span>
                                                <div className="btn-wrap">
                                                    <button
                                                        type='button'
                                                        className={`btn btn-sm ${Filter.visibility_mode !== 'local' && 'btn-success'}`}
                                                        onClick={() => matchPreferenceFilter('visibility_mode', 'global')} >Global</button>

                                                    <button
                                                        type='button'
                                                        className={`btn btn-sm ${Filter.visibility_mode === 'local' && 'btn-success'}`}
                                                        onClick={() => matchPreferenceFilter('visibility_mode', 'local')} >Local</button>
                                                </div>
                                                {/* ////////////////////// End visibility mode //////////////// */}

                                                <span className="sel-heading mt-4">Age</span>
                                                <select
                                                    className="profile-input"
                                                    value={Filter.age}
                                                    onChange={(e) => matchPreferenceFilter('age', e.target.value)}
                                                >

                                                    <option key={1} value={""}>Select Age</option>
                                                    {ageOptions.map((age) => (
                                                        <option key={age} value={age}>{age}</option>
                                                    ))}
                                                </select>
                                                {/* ////////////////////// End age //////////////// */}

                                                <span className="sel-heading mt-4">Country</span>
                                                <Select
                                                    options={countries}
                                                    onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                                    value={SelectedCountry}
                                                    className="mt-2"
                                                    placeholder="Select Country"
                                                />
                                                {/* ////////////////////// End country //////////////// */}

                                                <span className="sel-heading mt-4">Marital</span>
                                                <div className="radio-buttons">
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="single"
                                                            checked={Filter.marital === 'single'}
                                                            onChange={(e) => matchPreferenceFilter('marital', 'single')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Single</span>
                                                    </label>

                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="divorce"
                                                            checked={Filter.marital === 'divorce'}
                                                            onChange={(e) => matchPreferenceFilter('marital', 'divorce')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Divorce</span>
                                                    </label>

                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="other"
                                                            checked={Filter.marital === 'other'}
                                                            onChange={(e) => matchPreferenceFilter('marital', 'other')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Other</span>
                                                    </label>
                                                </div>
                                                {/* /////////////////// End marital //////////////// */}

                                                <span className="sel-heading mt-4">Occupation</span>
                                                <div className="radio-buttons">
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="employed"
                                                            checked={Filter.occupation === 'employed'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'employed')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Employed</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="retired"
                                                            checked={Filter.occupation === 'retired'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'retired')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Retired</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="student"
                                                            checked={Filter.occupation === 'student'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'student')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Student</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="self-employed"
                                                            checked={Filter.occupation === 'self-employed'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'self-employed')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Self-Employed</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="unemployed"
                                                            checked={Filter.occupation === 'unemployed'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'unemployed')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Unemployed</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="other"
                                                            checked={Filter.occupation === 'other'}
                                                            onChange={(e) => matchPreferenceFilter('occupation', 'other')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Other</span>
                                                    </label>
                                                </div>
                                                {/* /////////////////// End occupation //////////////// */}

                                                <span className="sel-heading mt-4">Body Type</span>
                                                <div className="radio-buttons">
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="athletic"
                                                            checked={Filter.bodyType === 'athletic'}
                                                            onChange={(e) => matchPreferenceFilter('bodyType', 'athletic')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Athletic</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="average"
                                                            checked={Filter.bodyType === 'average'}
                                                            onChange={(e) => matchPreferenceFilter('bodyType', 'average')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Average</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="petite"
                                                            checked={Filter.bodyType === 'petite'}
                                                            onChange={(e) => matchPreferenceFilter('bodyType', 'petite')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Petite</span>
                                                    </label>
                                                    <label className="radio-button">
                                                        <input
                                                            type="radio"
                                                            defaultValue="thick"
                                                            checked={Filter.bodyType === 'thick'}
                                                            onChange={(e) => matchPreferenceFilter('bodyType', 'thick')}
                                                        />
                                                        <div className="radio-circle" />
                                                        <span className="radio-label">Thick</span>
                                                    </label>
                                                </div>
                                                {/* /////////////////// End bodyType //////////////// */}

                                            </div>
                                        </div>




                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ThreeDots
                    visible={FilterLoader}
                    height="60"
                    width="60"
                    color="#8bbc46"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                <button className="button  btn-sm btn-theme full-rounded" style={{ marginRight: "6px" }} onClick={matchPreferenceList}>
                    Submit
                </button>
                <button className="button  btn-sm btn-theme full-rounded" onClick={matchPreferenceFilterReset}>
                    Reset
                </button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default memo(Index);