import React, { memo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PreLoader from '../PreLoader'
import ApiService from "../../services/ApiService";
import moment from "moment";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

function OutgoingCallHistory() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [outgoing_calllog_details, set_outgoing_calllog_details] = useState([]);


    const callHistoryList = useCallback(async () => {
        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST(`/call-history-new`, { userid: userData.id });
        console.log("call-history-new ", responce.data);

        // return;

        if (responce.data.status === "1") {

            set_outgoing_calllog_details(responce.data.outgoing_calllog_details);
        }

        setLoader(false);
    }, [userData.id]);


    const CallRecordDelete = async (id = "", userid = "", title = "") => {

        // "call_type":"incoming_call", // outgoing_call or  incoming_call
        // "id": "", // id use for single delete or leave it blank ("")
        // "userid":user_id  // userid use for All delete or leave it blank ("")


        Swal.fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete call record",
        }).then(async (result) => {

            if (result.isConfirmed) {
                /////////////////// Api call ///////////////////////
                var responce = await new ApiService().DELETE(`/call-history-delete`, {
                    call_type: "outgoing_call",
                    id: id,
                    userid: userid
                });
                console.log("delete-outgoing-call ", responce.data);

                // return;

                if (responce.data.status === "1") {
                    callHistoryList();
                }
            }
        });

    }

    useEffect(() => {
        callHistoryList();
    }, []);


    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}




        <div className="connections">


            <div className="d-flex justify-content-end">
                {outgoing_calllog_details.length > 0 && <Button className="btn btn-sm btn-danger mt-2 mb-2" onClick={() => CallRecordDelete("", userData.id, "Do you want to remove all Outgoing Calls History?")}>
                    <i className="fa-solid fa-trash"></i> Clear All
                </Button>}
            </div>

            <div class="tab-content" id="pills-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="outgoingcall"
                    role="tabpanel"
                    aria-labelledby="outgoingcall-tab"
                    tabindex="0"
                >
                    {/*  outgoing_calllog_details  */}
                    {outgoing_calllog_details.map((item, index) => (<>
                        <div className="profile" key={index} >
                            <img className="img-fluid me-2" src={item.profile_pic_pro_resize_200_url} alt="" onClick={() => navigate("/view-profile", {
                                state: {
                                    profile_id: item.receiverid,
                                    page_show_permission: true
                                }
                            })} />
                            <div className="details">
                                <h2>{item.name}</h2>
                                <p> {item.call_type === "videocall" ? "Video Call " : "Audio Call "}</p>
                                <p> Amount: ${item.amount}</p>
                                <p> Call Duration: {Math.ceil(Number(item.call_duration) / 60)} min</p>
                                {/* <p> Date: {moment.unix(item.datetime).fromNow()}</p> */}
                                <p> Date: {moment.unix(item.datetime).format('MM/DD/YYYY')}</p>
                            </div>
                            <div className="outgoingCallDel">
                                <Button type="button" className="btn btn-sm btn-danger" onClick={() => CallRecordDelete(item.id, "", "Do you want to delete this record?")}>
                                    <i className="fa-solid fa-trash"></i>
                                </Button>
                            </div>
                        </div>
                    </>))}
                </div>


            </div>
            {/* sub-tab-end  */}









        </div>

    </>)
}

export default memo(OutgoingCallHistory);