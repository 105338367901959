import React, { memo, useEffect, useState } from 'react'
import ApiService from '../services/ApiService';
import parse from 'html-react-parser';


function TermsCondition() {

    const [Items, setItems] = useState({
        about_us: "",
        privacy_policy: "",
        product_services: "",
        return_refund_policy: "",
        terms_condition: "",
        NEIGHBORING_WORLD_IN_REAL_TIME: "",
        Why_Real_Time_Village: "",
        disclaimer: "",
        Step_To_Find_Your_Connection_text: "",
        Step_To_Find_Your_Connection_step_1: "",
        Step_To_Find_Your_Connection_step_1_content: "",
        Step_To_Find_Your_Connection_step_2: "",
        Step_To_Find_Your_Connection_step_2_content: "",
        Step_To_Find_Your_Connection_step_3: "",
        Step_To_Find_Your_Connection_step_3_content: ""
    });

    const getAllData = async () => {
        const responce = await new ApiService().GET("/get-all-static-content");

        if (responce.data.status === '1') {
            setItems(responce.data.data);
        }
    };


    useEffect(() => {
        getAllData();
    }, [])


    return (<>
        <div className="ful-hei">
            {parse(Items.terms_condition)}
        </div>
    </>)
}

export default memo(TermsCondition)