import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import PreLoader from "../PreLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MatchPreferencesSchama } from "../../Schemas";
import { Country } from "country-state-city";
import ApiService from '../../services/ApiService';
import { toast } from "react-toastify";
import Select from 'react-select';

const heightData = [
    { label: 'Under 5’0"', value: '5.0' },
    { label: '5’1"', value: '5.1' },
    { label: '5’2"', value: '5.2' },
    { label: '5’3"', value: '5.3' },
    { label: '5’4"', value: '5.4' },
    { label: '5’5"', value: '5.5' },
    { label: '5’6"', value: '5.6' },
    { label: '5’7"', value: '5.7' },
    { label: '5’8"', value: '5.8' },
    { label: '5’9"', value: '5.9' },
    { label: '6’0"', value: '6.0' },
    { label: '6’1"', value: '6.1' },
    { label: '6’2"', value: '6.2' },
    { label: '6’3"', value: '6.3' },
    { label: '6’4"', value: '6.4' },
    { label: '6’5"', value: '6.5' },
    { label: '6’6"', value: '6.6' },
    { label: '6’7"', value: '6.7' },
    { label: '6’8"', value: '6.8' },
    { label: '6’9"', value: '6.9' },
    { label: '7’0"', value: '7.0' },
    { label: '7’1"', value: '7.1' },
    { label: '7’2"', value: '7.2' },
    { label: '7’3"', value: '7.3' },
    { label: '7’4"', value: '7.4' },
    { label: '7’5"', value: '7.5' },
]



function EditMatchPreferences() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { userData } = useSelector(state => state.auth);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [countries, setCountries] = useState([]);
    const [SelectedCountry, setSelectedCountry] = useState(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks, no-unused-vars
    const [Loader, setLoader] = useState(false);



    // /////////////// Form value ////////////////////
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        // getValues,
        // trigger,
        // control,
        formState: { errors },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
        resolver: yupResolver(MatchPreferencesSchama),
        mode: 'all'
    });


    const ChooseCountry = (data) => {
        setSelectedCountry(data);

        // console.log(data)

        setValue("country", data.value)

    }


    const getPreferenceDetails = async () => {

        setLoader(true);

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/view-preference", { userid: userData.id });

        var resData = responce.data.user_preference_details[0];
        console.log("getPreferenceDetails ", resData);

        setValue("age", resData.age);
        setValue("country", resData.country);
        setValue("city", resData.city);
        setValue("zipcode", resData.zipcode);
        setValue("gender", resData.gender);
        setValue("occupation", resData.occupation);
        setValue("marital", resData.marital);
        setValue("education", resData.education);
        setValue("height", resData.height);
        setValue("bodyType", resData.bodyType);

        if (resData.country) {
            const countryByIso2 = Country.getCountryByCode(resData.country);
            console.log("countryByIso2", countryByIso2);
            setSelectedCountry({ value: countryByIso2.isoCode, label: countryByIso2.name });
        }


        setLoader(false);

    }


    const submit = async (data) => {

        // console.log(data)

        var profile_data = {
            bodyType: data.bodyType,
            height: data.height,
            education: data.education,
            marital: data.marital,
            occupation: data.occupation,
            gender: data.gender,
            zipcode: data.zipcode,
            city: data.city,
            country: data.country,
            age: data.age,
            userid: userData.id
        }

        /////////////////// Api call ///////////////////////
        var responce = await new ApiService().POST("/edit-preference", profile_data);


        if (responce.data.status == '1') {
            toast.success("Preference update successfully")
        }

    }



    useEffect(() => {
        const fetchCountries = () => {
            const countryList = Country.getAllCountries();
            const options = countryList.map(item => ({ value: item.isoCode, label: item.name }));

            setCountries(options);
        };

        fetchCountries();
    }, [])


    useEffect(() => {
        getPreferenceDetails();
    }, [])

    return (<>
        {/* ==== Loader ==== */}
        <PreLoader Loader={Loader} />
        {/* ==== Loader ==== */}

        <div className="edit-profile">

            <form onSubmit={handleSubmit(submit)}>
                <div>
                    <div className="row mt-4">

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Age
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Age"
                                aria-label="Last name"
                                {...register("age")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.age?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Height
                            </label>
                            <select className="form-select" {...register("height")}>
                                <option value="">Select Height</option>
                                {heightData.map((item, index) => (<>
                                    <option value={item.value}>{item.label}</option>
                                </>))}
                            </select>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.height?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Country
                            </label>
                            {/* <select
                                className="form-select"
                                aria-label="Default select example"
                                {...register("country")}
                            >
                                <option value="" key={"0"}>Select Country</option>
                                {countries.map((country) => (
                                    <option
                                        key={country.isoCode}
                                        value={country.isoCode}
                                    >
                                        {country.name}
                                    </option>
                                ))}
                            </select> */}

                            <Select
                                options={countries}
                                onChange={(selectedOption) => ChooseCountry(selectedOption)}
                                value={SelectedCountry}
                                className="mt-2"
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.country?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                City
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                aria-label="Last name"
                                {...register("city")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.city?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Zip Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Zip Code"
                                aria-label="Last name"
                                {...register("zipcode")}
                            />

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.zipcode?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Gender
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="male"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Male</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="female"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Female</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("gender")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.gender?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Marital
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="single"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Single</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="divorced"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Divorced</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("marital")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.marital?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Education
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="no_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">No Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="high_school_diploma_or_equivalent"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">
                                        High School Diploma / Equivalent
                                    </span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="some_college"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Some College</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="associate_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Associate Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="bachelor"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Bachelor</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="master_degree"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Master Degree</span>
                                </label>


                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="doctorate_post_doctorate"
                                        {...register("education")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Doctorate / Post-Doctorate</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.education?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Occupation
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="employed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Employed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="retired"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Retired</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="student"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Student</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="self-employed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Self-Employed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="unemployed"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Unemployed</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="other"
                                        {...register("occupation")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Other</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.occupation?.message}
                            </p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Body Type
                            </label>

                            <div className="radio-buttons">
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="athletic"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Athletic</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="average"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Average</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="petite"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Petite</span>
                                </label>
                                <label className="radio-button">
                                    <input
                                        type="radio"
                                        defaultValue="thick"
                                        {...register("bodyType")}
                                    />
                                    <div className="radio-circle" />
                                    <span className="radio-label">Thick</span>
                                </label>
                            </div>

                            <p style={{ color: "red" }} className="form-field-error">
                                {errors.bodyType?.message}
                            </p>
                        </div>


                        {/* ================== demo ===================== */}
                        {/* <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Age
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">32</option>
                                <option value={1}>33</option>
                                <option value={2}>34</option>
                                <option value={3}>35</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Gender
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">Male</option>
                                <option value={1}>Female</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="inputEmail4" className="form-label">
                                Occupation
                            </label>
                            <select
                                className="form-select"
                                aria-label="Default select example"
                            >
                                <option selected="">Employed</option>
                                <option value={1}>Self Employed</option>
                            </select>
                        </div> */}
                        {/* ================== End demo ===================== */}

                        <div className="section-field text-uppercase text-center mt-2">
                            {" "}
                            <button
                                type='submit'
                                className="button  btn-lg btn-theme full-rounded animated right-icn"
                            >
                                <span>
                                    Save
                                    <i
                                        className="glyph-icon flaticon-hearts"
                                        aria-hidden="true"
                                    />
                                </span>
                            </button>{" "}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default EditMatchPreferences